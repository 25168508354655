import React from 'react';
import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  value: string;
  onValueChange: (value: boolean) => void;
}

function BooleanEditor({ value, onValueChange }: Props): JSX.Element {
  return (
    <Select
      input={<Input />}
      value={value ? 'Yes' : 'No'}
      onChange={(event) => onValueChange(event.target.value === 'Yes')}
      style={{ width: '100%' }}
    >
      <MenuItem value="Yes">Ja</MenuItem>
      <MenuItem value="No">Nein</MenuItem>
    </Select>
  );
}

export default BooleanEditor;
