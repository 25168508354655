import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function TextEditor({ value, onValueChange }: Props) {
  const [inputValue, setInputValue] = useState(value || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (newValue.trim() === newValue && newValue.trim() !== '') {
      setInputValue(newValue);
      onValueChange(newValue);
    }
  };

  return (
    <TextField
      value={inputValue}
      onChange={handleChange}
      error={inputValue.trim() !== inputValue || inputValue.trim() === ''}
      helperText={inputValue.trim() !== inputValue || inputValue.trim() === '' ? 'Incorrect entry' : ''}
    />
  );
}

export default TextEditor;
