import React, { useCallback } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BrowserRouter } from 'react-router-dom';
import AdapterDateFns from '@date-io/date-fns';
import { HelmetProvider } from 'react-helmet-async';
import Router from './Routes';
import CommunicationProvider from './context/CommunicationProvider/CommunicationProvider';
import DataContextProvider from './context/DataProvider/DataProvider';
import UserProvider from './context/UserProvider/UserProvider';
import ErrorMessage from './components/ErrorMessage/ErrorMessage';
import { LoadingProvider } from './context/LoadingProvider/LoadingContext';
import Loading from './components/LoadingDialog/Loading';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ThemeProvider from './theme';
import SnackbarProvider from './components/snackbar/SnackbarProvider';
import { SettingsProvider } from './components/settings/SettingsContext';
import ThemeSettings from './components/settings/ThemeSettings';

function App() {
  const [userToken, setUserToken] = React.useState<string>('');

  const setApolloClientToken = useCallback(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setUserToken(token);
    }
  }, []);

  return (
    <LoadingProvider>
      <ErrorMessage token={userToken}>
        <UserProvider>
          <DataContextProvider>
            <CommunicationProvider>
              <HelmetProvider>
                <SettingsProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <BrowserRouter>
                      <MotionLazyContainer>
                        <ThemeProvider>
                          <ThemeSettings>
                            <SnackbarProvider>
                              <Loading />
                              <Router setUserToken={setUserToken} setApolloClientToken={setApolloClientToken} />
                            </SnackbarProvider>
                          </ThemeSettings>
                        </ThemeProvider>
                      </MotionLazyContainer>
                    </BrowserRouter>
                  </LocalizationProvider>
                </SettingsProvider>
              </HelmetProvider>
            </CommunicationProvider>
          </DataContextProvider>
        </UserProvider>
      </ErrorMessage>
    </LoadingProvider>
  );
}

export default App;
