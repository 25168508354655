import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import { Grid, Box } from '@mui/material';
import { useFormikContext, FastField } from 'formik';
import { CountryCode, parsePhoneNumberWithError, ParseError } from 'libphonenumber-js';
import { AgentInputType } from '../../../types/AgentInputType.types';
import DataContext from '../../../context/DataProvider/DataContext';
import DebounceTextField from '../../../components/DebounceTextfield';
import { Laender, COUNTRYCODE } from '../../../types/Country.types';
import AgentFormChangeCorpEmail from './AgentFormChangeCorpEmail';
import InfoButton from '../../../components/InfoButton';
import replaceUmlaute from '../../../services/replaceUmlaute';
import AgentInit from '../../../types/initValues/Agent.init';

function addEmailSuffix(value: string, suffix: string) {
  const newValue = value.replace(suffix, '');
  return `${newValue}@${suffix}`;
}
function removeEmailSuffix(value: string, suffix: string) {
  const newValue = value.replace(`@${suffix}`, '');
  return `${newValue}`;
}

function AgentFormContactsData() {
  const { brachOffices } = useContext(DataContext);
  const { values, handleChange, errors, handleBlur, setFieldValue } = useFormikContext<AgentInputType>();

  const emailSuffix = useMemo(
    () => brachOffices.filter((item) => item.id === values.branch_office_id)[0]?.email_postfix,
    [brachOffices, values.branch_office_id],
  );

  const infoTextForPhoneFax =
    'Handelt es sich um eine ausländische Nummer, so wählen Sie zu erst das ensprechende Land aus in der Anschrift des Beraters. Eine Nummer darf wie folgt aufgebaut sein [ +, 0-9 ]. Die Nummer wird nachdem Eintragen formatiert.';

  const handlePhoneFormat = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      handleBlur(event);
      if (values.country_id === Laender.deutschland) {
        if (values.phone) {
          try {
            const formatetPhoneNumber = parsePhoneNumberWithError(
              values.phone,
              COUNTRYCODE[values.country_id as Laender].code as CountryCode,
            );
            if (formatetPhoneNumber.isValid()) setFieldValue('phone', formatetPhoneNumber.formatNational());
          } catch (parseError) {
            if (parseError instanceof ParseError) {
              console.error(`parseError in handlePhoneFormat `, parseError.message);
            }
          }
        }
      } else if (values.phone) {
        try {
          const formatetPhoneNumber = parsePhoneNumberWithError(
            values.phone,
            COUNTRYCODE[values.country_id as Laender].code as CountryCode,
          );
          if (formatetPhoneNumber.isValid()) setFieldValue('phone', formatetPhoneNumber.formatInternational());
        } catch (parseError) {
          if (parseError instanceof ParseError) {
            console.error(`parseError in handlePhoneFormat`, parseError.message);
          }
        }
      }
    },
    [values.country_id, values.phone],
  );
  const handleFaxFormat = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      handleBlur(event);
      if (values.country_id === Laender.deutschland) {
        if (values.fax) {
          try {
            const formatetPhoneNumber = parsePhoneNumberWithError(
              values.fax,
              COUNTRYCODE[values.country_id as Laender].code as CountryCode,
            );
            if (formatetPhoneNumber.isValid()) setFieldValue('fax', formatetPhoneNumber.formatNational());
          } catch (parseError) {
            if (parseError instanceof ParseError) {
              console.error(`parseError in handleFaxFormat`, parseError.message);
            }
          }
        }
      } else if (values.fax) {
        try {
          const formatetPhoneNumber = parsePhoneNumberWithError(
            values.fax,
            COUNTRYCODE[values.country_id as Laender].code as CountryCode,
          );
          if (formatetPhoneNumber.isValid()) setFieldValue('fax', formatetPhoneNumber.formatInternational());
        } catch (parseError) {
          if (parseError instanceof ParseError) {
            console.error(`parseError in handleFaxFormat`, parseError.message);
          }
        }
      }
    },
    [values.country_id, values.fax],
  );

  const handleMobileFormat = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      handleBlur(event);
      if (values.country_id === Laender.deutschland) {
        if (values.mobile) {
          try {
            const formatetPhoneNumber = parsePhoneNumberWithError(
              values.mobile,
              COUNTRYCODE[values.country_id as Laender].code as CountryCode,
            );
            if (formatetPhoneNumber.isValid()) setFieldValue('mobile', formatetPhoneNumber.formatNational());
          } catch (parseError) {
            if (parseError instanceof ParseError) {
              console.error(`parseError in handleMobileFormat`, parseError.message);
            }
          }
        }
      } else if (values.mobile) {
        try {
          const formatetPhoneNumber = parsePhoneNumberWithError(
            values.mobile,
            COUNTRYCODE[values.country_id as Laender].code as CountryCode,
          );
          if (formatetPhoneNumber.isValid()) setFieldValue('mobile', formatetPhoneNumber.formatInternational());
        } catch (parseError) {
          if (parseError instanceof ParseError) {
            console.error(`parseError in handleMobileFormat`, parseError.message);
          }
        }
      }
    },
    [values.country_id, values.mobile],
  );

  useEffect(() => {
    if (values.agent_id === AgentInit.agent_id) {
      setFieldValue(
        'email_corp',
        addEmailSuffix(
          `${replaceUmlaute(values.name.toLocaleLowerCase())}.${replaceUmlaute(values.surname.toLocaleLowerCase())}`,
          emailSuffix,
        ),
      );
    }
  }, [values.name, values.surname, values.branch_office_id, values.agent_id]);
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FastField name="phone">
          {() => (
            <DebounceTextField
              label="Telefon"
              name="phone"
              aria-label="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handlePhoneFormat}
              helperText={errors.phone}
              error={Boolean(errors.phone) || false}
              fullWidth
              InputProps={{
                endAdornment: <InfoButton tooltiptext={infoTextForPhoneFax} />,
              }}
            />
          )}
        </FastField>
      </Grid>
      <Grid item>
        <FastField name="mobile">
          {() => (
            <DebounceTextField
              label="Mobiltelefon"
              name="mobile"
              aria-label="mobile"
              value={values.mobile || ''}
              onChange={handleChange}
              onBlur={handleMobileFormat}
              helperText={errors.mobile}
              error={Boolean(errors.mobile) || false}
              fullWidth
              InputProps={{
                endAdornment: <InfoButton tooltiptext={infoTextForPhoneFax} />,
              }}
            />
          )}
        </FastField>
      </Grid>
      <Grid item>
        <FastField name="fax">
          {() => (
            <DebounceTextField
              label="Fax"
              name="fax"
              aria-label="fax"
              value={values.fax || ''}
              onChange={handleChange}
              onBlur={handleFaxFormat}
              helperText={errors.fax}
              error={Boolean(errors.fax) || false}
              fullWidth
              InputProps={{
                endAdornment: <InfoButton tooltiptext={infoTextForPhoneFax} />,
              }}
            />
          )}
        </FastField>
      </Grid>
      <Grid item>
        {values.agent_id === AgentInit.agent_id ? (
          <FastField name="email_corp">
            {() => (
              <DebounceTextField
                data-testid="agent-personaldata-email_corp"
                type="input"
                label="Geschäfts email"
                aria-label="email_corp"
                name="email_corp"
                value={removeEmailSuffix(values.email_corp || '', emailSuffix)}
                onChange={(e) => {
                  setFieldValue('email_corp', addEmailSuffix(e.target.value, emailSuffix));
                }}
                onBlur={handleBlur}
                helperText={errors.email_corp || `@${emailSuffix}`}
                error={Boolean(errors.email_corp) || false}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InfoButton tooltiptext="Hinterlegen Sie in diesem Feld nur den E-Mail Prefix. Eine E-Mail Adresse darf wie folgt aufgebaut sein [a-z, A-Z, 0-9, ._%+-] Achten Sie zusätzlich auf Leerzeichen, diese sind nicht erlaubt" />
                  ),
                }}
              />
            )}
          </FastField>
        ) : (
          <FastField name="email_corp">
            {() => (
              <Box style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <DebounceTextField
                  data-testid="agent-personaldata-email_corp"
                  InputProps={{
                    endAdornment: <AgentFormChangeCorpEmail />,
                  }}
                  disabled
                  aria-label="email_corp"
                  type="input"
                  label="Geschäfts email"
                  name="email_corp"
                  value={values.email_corp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                />
              </Box>
            )}
          </FastField>
        )}
      </Grid>

      <Grid item>
        <FastField name="email_private">
          {() => (
            <DebounceTextField
              InputProps={{
                endAdornment: (
                  <InfoButton tooltiptext="Eine E-Mail Adresse darf wie folgt aufgebaut sein [a-z, A-Z, 0-9, ._%+-] @ [a-z, A-Z, 0-9,-] . [a-z, A-Z] Achten Sie zusätzlich auf Leerzeichen, diese sind nicht erlaubt  " />
                ),
              }}
              data-testid="agent-personaldata-email_private"
              label="Private email"
              name="email_private"
              aria-label="email_private"
              value={values.email_private}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.email_private}
              error={Boolean(errors.email_private) || false}
              fullWidth
            />
          )}
        </FastField>
      </Grid>
    </Grid>
  );
}

export default AgentFormContactsData;
