interface Props {
  serviceType: string;
  date: string | Date;
  startOrEndDate: string;
}

function startOrEndDateForService({ serviceType, date, startOrEndDate }: Props) {
  const newDate = date ? new Date(date) : new Date();
  if (serviceType === 'MonthlyService' || serviceType === 'YearlyService') {
    if (startOrEndDate === 'endDate') {
      newDate.setFullYear(newDate.getFullYear(), newDate.getMonth() + 1, 0); // last day of the month
    } else {
      newDate.setFullYear(newDate.getFullYear(), newDate.getMonth(), 1); // first day of the month
    }
  }
  const arr = newDate.toISOString().split('T');
  return arr[0];
}

export default startOrEndDateForService;
