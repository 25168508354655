import React from 'react';
import { Navigate } from 'react-router-dom';
import AgentEditPage from './pages/AgentEditPage/AgentEditPage';
import FinancePage from './pages/Finace/AllReceiptsPage';
import AllAgentsPage from './pages/AgentTable';
import AllInteressentenPage from './pages/Leads/LeadsTable';
import DashboardPage from './pages/DashboardPage';
import ReceiptPage from './pages/Finace/ReceiptEditPage';
import OptionsPage from './pages/Options/BookingAccount/OptionsPage';
import ServicesPage from './pages/Options/ServicesPage';
import RankPage from './pages/Options/RankPage/RankPage';
import LevelsPage from './pages/Options/LevelsPage/LevelsPage';
import UserPage from './pages/Options/UserPage/UserPage';
import BranchofficePage from './pages/Options/BrachofficePage/BranchofficePage';
import TaxPage from './pages/Options/TaxPage';
import CounrysPage from './pages/Options/CountrysPage/index';
import ContractTypsPage from './pages/Options/ContractTypsPage/ContractTypsPage';
import MontlyAccountingPage from './pages/Finace/MontlyAccountingPage/MountlyAccountingPage';
import AdminPage from './pages/Options/AdminPage/index';
import Map from './pages/Map';
import ImportShopReceipts from './pages/Finace/ImportShopReceipts';
import InteressentPage from './pages/Leads/EditPage';
import ServicesReportPage from './pages/Reports/ServicesReportPage/index';

export enum PagePathname {
  dashboard = '/dashboard',
  alleBerater = '/all-agents',
  beraterErstellen = '/create-agent',
  beraterBearbeiten = '/agent/:number',
  logout = '/logout',

  // Berichte
  berichte = '/berichte',
  karte = '/berichte/map',
  lesitungReport = '/berichte/servicesReport',

  // Buchhaltung
  buchhaltung = '/buchhaltung',
  alleBelege = '/buchhaltung/finance',
  belege = '/buchhaltung/receipt-edit/:number',
  belegeErstellen = '/buchhaltung/receipt-create',
  abschluss = '/buchhaltung/montlyAccounting',
  shopifyBelege = '/buchhaltung/import-shop-receipts',

  // Einstellung
  einstellung = '/einstellung',
  buchungskonten = '/einstellung/bookingAccount',
  ränge = '/einstellung/ranks',
  levels = '/einstellung/levels',
  benutzer = '/einstellung/users',
  marken = '/einstellung/branchOffice',
  leistungen = '/einstellung/services',
  steuern = '/einstellung/tax',
  länder = '/einstellung/coutrys',
  vertragsarten = '/einstellung/contractTyps',
  adminPanel = '/einstellung/admin-panel',
}

export type Page = {
  title: string;
  path: string;
  render: JSX.Element;
  showInNavbar: boolean;
};

let pages: Page[] = [
  {
    title: 'Dashboard',
    path: PagePathname.dashboard,
    render: <DashboardPage />,
    showInNavbar: true,
  },
  {
    title: 'Berater',
    path: PagePathname.alleBerater,
    render: <AllAgentsPage />,
    showInNavbar: true,
  },

  {
    title: 'Belege',
    path: PagePathname.alleBelege,
    render: <FinancePage />,
    showInNavbar: true,
  },
  {
    title: 'BasePath Buchhaltung',
    path: PagePathname.buchhaltung,
    render: <Navigate to={PagePathname.alleBelege} replace />,
    showInNavbar: true,
  },
  {
    title: 'Abschluss',
    path: PagePathname.abschluss,
    render: <MontlyAccountingPage />,
    showInNavbar: true,
  },
  {
    title: 'Buchungskonten',
    path: PagePathname.buchungskonten,
    render: <OptionsPage />,
    showInNavbar: true,
  },
  {
    title: 'BasePath Einstellung',
    path: PagePathname.einstellung,
    render: <Navigate to={PagePathname.buchungskonten} replace />,
    showInNavbar: true,
  },
  {
    title: 'Ränge',
    path: PagePathname.ränge,
    render: <RankPage />,
    showInNavbar: true,
  },
  {
    title: 'Levels',
    path: PagePathname.levels,
    render: <LevelsPage />,
    showInNavbar: true,
  },
  {
    title: 'Benutzer',
    path: PagePathname.benutzer,
    render: <UserPage />,
    showInNavbar: true,
  },
  {
    title: 'Länder',
    path: PagePathname.länder,
    render: <CounrysPage />,
    showInNavbar: true,
  },

  {
    title: 'Vertragsarten',
    path: PagePathname.vertragsarten,
    render: <ContractTypsPage />,
    showInNavbar: true,
  },
  {
    title: 'Marken',
    path: PagePathname.marken,
    render: <BranchofficePage />,
    showInNavbar: true,
  },
  {
    title: 'Leistungen',
    path: PagePathname.leistungen,
    render: <ServicesPage />,
    showInNavbar: true,
  },
  {
    title: 'Leistungen Bericht',
    path: PagePathname.lesitungReport,
    render: <ServicesReportPage />,
    showInNavbar: true,
  },
  {
    title: 'BasePath Berichte',
    path: PagePathname.berichte,
    render: <Navigate to={PagePathname.lesitungReport} />,
    showInNavbar: true,
  },
  {
    title: 'Steuern',
    path: PagePathname.steuern,
    render: <TaxPage />,
    showInNavbar: true,
  },

  {
    title: 'Berater erstellen',
    path: PagePathname.beraterErstellen,
    render: <AgentEditPage />,
    showInNavbar: true,
  },
  {
    title: 'Berater bearbeiten',
    path: PagePathname.beraterBearbeiten,
    render: <AgentEditPage />,
    showInNavbar: true,
  },
  {
    title: 'Belege erstellen',
    path: PagePathname.belegeErstellen,
    render: <ReceiptPage />,
    showInNavbar: true,
  },
  {
    title: 'Belege',
    path: PagePathname.belege,
    render: <ReceiptPage />,
    showInNavbar: true,
  },
  {
    title: 'Admin panel',
    path: PagePathname.adminPanel,
    render: <AdminPage />,
    showInNavbar: true,
  },
  {
    title: 'Karte',
    path: PagePathname.karte,
    render: <Map />,
    showInNavbar: true,
  },
  {
    title: 'Shopify Belege',
    path: PagePathname.shopifyBelege,
    render: <ImportShopReceipts />,
    showInNavbar: true,
  },
];

if (process.env.NODE_ENV === 'development') {
  pages = pages.concat([
    {
      title: 'Interessenten',
      path: '/all-interessents',
      render: <AllInteressentenPage />,
      showInNavbar: false,
    },
    {
      title: 'Interessenten anlegen',
      path: '/create-interessent',
      render: <InteressentPage />,
      showInNavbar: false,
    },
    {
      title: 'Interessenten bearbeiten',
      path: '/interessent/:number',
      render: <InteressentPage />,
      showInNavbar: false,
    },
    {
      title: 'Interessenten anlegen',
      path: '/create-interessent',
      render: <InteressentPage />,
      showInNavbar: true,
    },
    {
      title: 'Interessenten bearbeiten',
      path: '/interessent/:number',
      render: <InteressentPage />,
      showInNavbar: true,
    },
  ]);
}

export default pages;
