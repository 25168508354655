import { gql } from '@apollo/client';

const createContractTypes = gql`
  mutation ($variables: ContractTypeInput!) {
    createContractType(input: $variables) {
      id
      title
      is_default
    }
  }
`;
export default createContractTypes;
