enum AgentStatus {
  inProcess = 9,
  active = 3,
  restingCooperation = 4,
  acquired = 6,
  interest = 8,
  dismissed = 5,
  failed = 10,
}

export default AgentStatus;
