import React from 'react';
import { useFormikContext } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import ReceiptInput from '../../../../types/ReceiptInput.types';

function ReceiptEditYear() {
  const { setFieldValue, handleBlur, values, errors, touched } = useFormikContext<ReceiptInput>();
  const currentYear = new Date().getUTCFullYear();
  const currentMonth = new Date().getUTCMonth();

  return (
    <Autocomplete
      id="ReceiptEditYearLabel"
      fullWidth
      options={[currentYear, currentMonth === 0 ? currentYear - 1 : currentYear + 1].sort((a, b) => a - b)}
      getOptionLabel={(year) => `${year}`}
      onChange={(e, value) => {
        setFieldValue('year', value || null);
      }}
      value={values.year}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          name="year"
          label="Buchungskonto"
          error={Boolean(errors.year && touched.year) || false}
          helperText={errors.year && touched.year && errors.year}
          required
        />
      )}
    />
  );
}

export default ReceiptEditYear;
