import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogActions, DialogTitle, Button, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import uploadAgentImage from './Avatar.api';
import { AgentInputType } from '../../../types/AgentInputType.types';
import Agentv2 from '../../../types/Agentv2.types';

interface Props {
  currentAgentAvatar: string | undefined;
  agentID: number | string | undefined | null;
  formDirty: boolean;
  callback?: () => void;
}

function AgentAvatar({ currentAgentAvatar, agentID, formDirty, callback }: Props) {
  const { setFieldValue } = useFormikContext<AgentInputType | Agentv2>();
  const { request, isLoading, data } = uploadAgentImage();
  const [open, setOpen] = useState(false);
  const [fotoToBig, setFotoToBig] = useState(false);

  useEffect(() => {
    if (data) {
      setFieldValue('thumbnail', `/thumbnails/${data.uploadImage}`);
    }
  }, [data]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files || [];
      const firstImage = files[0];

      setFotoToBig(false);
      const maxPhotoSizeInByte = 10485760;
      const { size } = files[0];

      if (size >= maxPhotoSizeInByte) {
        setFotoToBig(true);
      } else if (firstImage && agentID) {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            if (firstImage) request(firstImage, agentID).catch(() => 'do nothing');
          },
          false,
        );

        if (firstImage) {
          reader.readAsDataURL(firstImage);
        }
      }
    },
    [agentID],
  );

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>ungespeicherte Änderungen</DialogTitle>
        <DialogContent>Bitte Speichern Sie zuerst ihren Stand</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Foto ändern">
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (formDirty || !agentID) setOpen(true);
            else document.getElementById('AgentEditAvatar')?.click();
          }}
        >
          {isLoading && (
            <Box display="flex" width="213px" height="213px" justifyContent="center" alignItems="center">
              <CircularProgress size="30px" />
            </Box>
          )}
          {!isLoading && (
            <Avatar
              data-testid="agentedit-avatar"
              src={currentAgentAvatar}
              style={{ margin: '16px', width: '80%', height: '80%' }}
            />
          )}
          <input
            data-testid="agentedit-avatar-input"
            hidden
            accept="image/*"
            id="AgentEditAvatar"
            type="file"
            onChange={handleChange}
            multiple
          />
        </Box>
      </Tooltip>
      {fotoToBig ? (
        <Typography align="center" color="error" variant="subtitle2">
          Bild ist zu groß
        </Typography>
      ) : (
        <Typography align="center" color="textSecondary" variant="subtitle2">
          maximale Bildgröße 10MB
        </Typography>
      )}
    </>
  );
}

export default AgentAvatar;
