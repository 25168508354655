import React from 'react';
import { Box, Typography, IconButton, Tooltip, useTheme, Stack } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';

type Props = {
  title: string;
  date: string;
  status: boolean;
  refreshDate: (title: string) => void;
};

function ItemProject({ title, date, status, refreshDate }: Props) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.grey[700];
  const PRIMARY_DARK = theme.palette.grey[400];

  const dateObj = new Date(date);
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent={'space-between'} alignItems="center">
        <Typography component="h3" fontSize="1.2em">
          {title}
        </Typography>
        <Tooltip title={`Berater mit ${title} synchronisieren`} arrow>
          <IconButton
            size="small"
            onClick={() => {
              refreshDate(title);
            }}
            component="span"
          >
            <SyncIcon
              sx={{
                fontSize: '30px',
                color: theme.palette.mode === 'dark' ? PRIMARY_DARK : PRIMARY_LIGHT,
              }}
            />
          </IconButton>
        </Tooltip>
      </Stack>

      <Stack direction="row" justifyContent={'space-between'}>
        <Stack spacing={1} width="100%" direction="column">
          <Stack direction="row" justifyContent={'space-between'}>
            <Typography component="span" fontSize="0.8em">
              letztes Update:
            </Typography>
            <Typography component="span" fontSize="0.8em">
              {`${day}.${month}.${year}`}
            </Typography>
          </Stack>

          <Stack direction="row" justifyContent={'space-between'}>
            <Typography component="span" fontSize="0.8em">
              Status:
            </Typography>
            <Box
              sx={{
                width: '20px',
                height: '20px',
                background: status ? 'green' : 'red',
                borderRadius: '50%',
              }}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ItemProject;
