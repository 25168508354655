import React, { useContext, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Level } from '../../../../../types/Level.types';
import onlyWhitespaceInString from '../../../../../services/onlyWhitespaceInString';
import DataContext from '../../../../../context/DataProvider/DataContext';
import titleIsIdentical from '../../../../../services/titleIsIdentical';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function validateValue(
  inputValue: Props['value'],
  currentValue: string | undefined,
  listOfData: Level[],
): { error: boolean; helperText: string } {
  const nameIsIdentical: boolean = titleIsIdentical({ inputValue, currentValue, listOfData });
  if (!inputValue) {
    return {
      error: true,
      helperText: 'Pflichtfeld',
    };
  }
  if (onlyWhitespaceInString(inputValue)) {
    return {
      error: true,
      helperText: 'Darf nicht nur aus Leerzeichen bestehen',
    };
  }
  if (inputValue.length > 128) {
    return {
      error: true,
      helperText: 'Max 128 Zeichen lang',
    };
  }
  if (nameIsIdentical) {
    return {
      error: true,
      helperText: 'Titel ist bereits vorhanden',
    };
  }
  return {
    error: false,
    helperText: '',
  };
}

function TextEditor({ value, onValueChange }: Props) {
  const { level } = useContext(DataContext);
  const [helperText, setHelperText] = useState('');
  const [error, setError] = useState(false);

  const { current: initialValue } = useRef(value);

  useEffect(() => {
    const valid = validateValue(value, initialValue, level);
    setError(valid.error);
    setHelperText(valid.helperText);
  }, [value]);

  return (
    <Box>
      <TextField
        value={value || ''}
        onChange={(event) => onValueChange(event.target.value)}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
}

export default TextEditor;
