import calculateAge from './services/calculateAge';
import Agentv2 from '../../types/Agentv2.types';

export default [
  { name: 'name', title: 'Name' },
  { name: 'surname', title: 'Nachname' },
  {
    name: 'birthday',
    title: 'Alter & Geburtstag',
    getCellValue: (row: Agentv2) => {
      calculateAge(row.birthday);
      const birthdayDateAngAge = `wird` + ` ${calculateAge(row.birthday)} am ${row.birthday}`;
      return birthdayDateAngAge;
    },
  },
  { name: 'month_of_birthday', title: 'Geburtsmonat' },
];
