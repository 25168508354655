import React, { useContext, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import getAllServicesQuery from '../../../../Apollo/queries/getAllServicesQuery';
import columns from './columnDefenition';
import Table from '../../../../components/Table';
import ServicesInit from '../../../../types/initValues/Services.init';
import { Service } from '../../../../types/Service.types';
import { MONTHS } from '../../../../services/config/CONST';
import TextEditor from './components/TextEditor';
import NumberEditor from './components/NumberEditor';
import TaxEditor from './components/TaxEditor';
import TypeEditor from './components/TypeEditor';
import StartMonthEditor from './components/StartMonthEditor';
import BookingAccountEditor from './components/BookingAccountEditor';
import commitChanges from './commitChanges';
import createServicesQuery from '../../../../Apollo/mutations/createServicesQuery';
import updateServicesQuery from '../../../../Apollo/mutations/updateServicesQuery';
import DataContext from '../../../../context/DataProvider/DataContext';
import AlertMessage from '../../../../components/AlertMessage/AlertMessage';

interface Prop {
  booking_account: string;
  tax: string;
  fixed_start_month: string | undefined | number | null;
  default_price: number;
  id: number;
  title: string;
  type: string;
}

const textColumns = ['title'];

function TextTypeProvider() {
  return <DataTypeProvider editorComponent={TextEditor} for={textColumns} />;
}

const numberColumns = ['default_price'];

function NuberTypeProvider() {
  return <DataTypeProvider editorComponent={NumberEditor} for={numberColumns} />;
}

const taxColumns = ['tax'];

function TaxTypeProvider() {
  return <DataTypeProvider editorComponent={TaxEditor} for={taxColumns} />;
}

const typeColumns = ['type'];

function TypeProvider() {
  return <DataTypeProvider editorComponent={TypeEditor} for={typeColumns} />;
}

const startMonthProvider = ['fixed_start_month'];

function StartMonthTypeProvider() {
  return <DataTypeProvider editorComponent={StartMonthEditor} for={startMonthProvider} />;
}

const bookingAccountColumns = ['booking_account'];

function BookingAccountTypeProvider() {
  return <DataTypeProvider editorComponent={BookingAccountEditor} for={bookingAccountColumns} />;
}

const Provider = [
  TextTypeProvider,
  NuberTypeProvider,
  TaxTypeProvider,
  TypeProvider,
  StartMonthTypeProvider,
  BookingAccountTypeProvider,
];

function AllServicesTable() {
  const [createServices, { data: dataCreateServices, error: errorCreateServices }] = useMutation(createServicesQuery);
  const [updateServices, { data: dataUpdateServices, error: errorUpdateServices }] = useMutation(updateServicesQuery);
  const { data, refetch } = useQuery(getAllServicesQuery);
  const { tax, bookingAccount, loaderFun } = useContext(DataContext);
  const editingStateColumnExtensions = [{ columnName: 'id', editingEnabled: false }];

  useEffect(() => {
    loaderFun.loadTax();
    loaderFun.loadBookingAccount();
  }, []);

  const allServices = (service: Service[]) => {
    const servicesData: Prop[] = [];
    if (service) {
      service.map((serviceItem) => {
        const normServiceData = {
          ...serviceItem,
          booking_account: serviceItem.booking_account.title,
          tax: `${serviceItem.tax.percent}% : ${serviceItem.tax.code}`,
          fixed_start_month: serviceItem.fixed_start_month,
        };
        MONTHS.map((month) => {
          if (month.id === normServiceData.fixed_start_month) {
            normServiceData.fixed_start_month = month.title;
          }
          return null;
        });
        servicesData.push(normServiceData);
        return null;
      });
    }
    return servicesData;
  };
  const row = allServices(data ? data?.getAllServices : [ServicesInit]);

  return (
    <>
      <Table
        row={row}
        columns={columns}
        defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
        onCommitChanges={({ added, changed }) =>
          commitChanges({
            added,
            changed,
            rows: data?.getAllServices,
            refetch,
            createServices,
            updateServices,
            tax,
            bookingAccount,
            MONTHS,
          })
        }
        columnExtensions={editingStateColumnExtensions}
        dataTypeProvider={Provider}
        editable
        showAddCommand
        showEditCommand
      />
      <AlertMessage
        typeMessage={(errorCreateServices || errorUpdateServices) && 'error'}
        alertMessage={(errorCreateServices || errorUpdateServices) && 'Es ist ein Fehler aufgetreten'}
        isAlertMessage={!!errorCreateServices || !!errorUpdateServices}
      />
      <AlertMessage
        typeMessage={
          (dataCreateServices && !errorCreateServices && 'success') ||
          (dataUpdateServices && !errorUpdateServices && 'success')
        }
        alertMessage={
          (dataCreateServices && !errorCreateServices && 'Leistung wurde erfolgreich erstellt') ||
          (dataUpdateServices && !errorUpdateServices && 'Leistung erfolgreich geändert')
        }
        isAlertMessage={
          (!!dataCreateServices && !errorCreateServices) || (!!dataUpdateServices && !errorUpdateServices)
        }
      />
    </>
  );
}

export default AllServicesTable;
