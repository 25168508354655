import React, { useContext } from 'react';
import { Grid, MenuItem, Select, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import DataContext from '../../../context/DataProvider/DataContext';
import { AgentInputType } from '../../../types/AgentInputType.types';
import DebounceTextField from '../../../components/DebounceTextfield';
import { AgentStatus } from '../../../types/Agent.types';
import Agentv2 from '../../../types/Agentv2.types';
import AgentInit from '../../../types/initValues/Agent.init';

function AgentFormMainInfo() {
  const { rank, level, brachOffices, contractType, agents } = useContext(DataContext);
  const { values, handleChange, errors, handleBlur, initialValues } = useFormikContext<AgentInputType | Agentv2>();

  const getManager = agents.filter((agent) => agent.rank.is_manager);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormControl
          fullWidth
          error={Boolean(errors.rank_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        >
          <InputLabel>Rang</InputLabel>
          <Select name="rank_id" value={values.rank_id || ''} onChange={handleChange} onBlur={handleBlur}>
            {rank?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.rank_id}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          error={Boolean(errors.level_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        >
          <InputLabel>Level</InputLabel>
          <Select name="level_id" value={values.level_id || ''} onChange={handleChange}>
            {level?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.level_id}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl
          disabled={values.agent_id !== AgentInit.agent_id}
          fullWidth
          error={Boolean(errors.branch_office_id) || false}
        >
          <InputLabel>Firma</InputLabel>
          <Select name="branch_office_id" value={values.branch_office_id || ''} onChange={handleChange}>
            {brachOffices?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.branch_office_id}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          error={Boolean(errors.contract_type_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        >
          <InputLabel>Vertragsart</InputLabel>
          <Select name="contract_type_id" value={values.contract_type_id || ''} onChange={handleChange}>
            {contractType?.map((item) => (
              <MenuItem key={item.id} value={item.id} disabled={!!(item.id === 3 && values.branch_office_id !== 1)}>
                <span>{item.title}</span>
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.contract_type_id}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item>
        <DebounceTextField
          type="number"
          label="myJACK Unit"
          name="jackplus_id"
          aria-label="jackplus_id"
          value={values.jackplus_id || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={
            initialValues.status_id === AgentStatus.active ||
            initialValues.status_id === AgentStatus.dismissed ||
            values.status_id === AgentStatus.failed
          }
          helperText={errors.jackplus_id}
          error={Boolean(errors.jackplus_id) || false}
          fullWidth
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          type="text"
          label="myJACK Login"
          name="myjack_login"
          aria-label="myjack_login"
          value={values.myjack_login || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={
            (initialValues.status_id === AgentStatus.active && initialValues.myjack_login !== null) ||
            initialValues.status_id === AgentStatus.dismissed ||
            values.status_id === AgentStatus.failed
          }
          helperText={errors.myjack_login}
          error={Boolean(errors.myjack_login) || false}
          fullWidth
        />
      </Grid>
      <Grid item>
        <DebounceTextField
          type="number"
          label="Kreditorennummer"
          name="agent_account_nr"
          aria-label="agent_account_nr"
          value={values.agent_account_nr || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={
            initialValues.status_id === AgentStatus.active ||
            initialValues.status_id === AgentStatus.dismissed ||
            values.status_id === AgentStatus.failed
          }
          helperText={errors.agent_account_nr}
          error={Boolean(errors.agent_account_nr) || false}
          fullWidth
        />
      </Grid>
      <Grid item>
        <FormControl
          fullWidth
          error={Boolean(errors.manager_id) || false}
          disabled={initialValues.status_id === AgentStatus.dismissed || values.status_id === AgentStatus.failed}
        >
          <InputLabel>Teamleiter</InputLabel>
          <Select name="manager_id" value={values.manager_id || ''} onChange={handleChange}>
            {getManager?.map((item) => (
              <MenuItem key={item.id} value={item.id.toString()}>
                {item.surname}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.manager_id}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default AgentFormMainInfo;
