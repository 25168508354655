import { ServiceByID } from '../../../../../types/ServiceByID.types';

function formatDate(date: string) {
  if (!date) return '';
  const arr = date.split('-');
  return `${arr[0]}-${arr[1]}`;
}

function normalizeServicesById(servicesById: ServiceByID[]) {
  if (servicesById) {
    const normData = servicesById.map((item: ServiceByID) => ({
      id: item.id ? item.id : '',
      start_date: item.start_date ? formatDate(item.start_date) : '',
      end_date: item.end_date ? formatDate(item.end_date) : '',
      agent_service_price: item.agent_service.price ? item.agent_service.price : '',
      agent_service_description: item.agent_service.description ? item.agent_service.description : '',
      service_default_price: item.agent_service.service.default_price ? item.agent_service.service.default_price : '',
      service_title: item.agent_service.service.title ? item.agent_service.service.title : '',
      service_type: item.agent_service.service.type ? item.agent_service.service.type : '',
      service_tax: `${item.agent_service.service.tax.percent.toString()}% : ${item.agent_service.service.tax.code}`
        ? `${item.agent_service.service.tax.percent.toString()}% : ${item.agent_service.service.tax.code}`
        : '',
      agent_service_agent_id: item.agent_service.agent_id ? item.agent_service.agent_id : '',
      customer_id: item.agent_service.customer_id ? item.agent_service.customer_id : '',
    }));
    return normData;
  }
  return [];
}

export default normalizeServicesById;
