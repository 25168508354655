import Agentv2 from '../../../types/Agentv2.types';

function calculateAge(birthDateString: Agentv2['birthday']) {
  const birthDate = new Date(birthDateString);

  const today = new Date();

  const age = today.getFullYear() - birthDate.getFullYear();

  return age;
}

export default calculateAge;
