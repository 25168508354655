import React from 'react';
import { FetchResult, gql, useMutation } from '@apollo/client';

const uploadAgentImage = (): {
  request: (
    image: File,
    agentID: number | string,
  ) => Promise<FetchResult<any, Record<string, any>, Record<string, any>>>;
  isLoading: boolean;
  error: unknown | null;
  data: { uploadImage: string } | undefined;
} => {
  const queryBody = gql`
    mutation ($image: Upload!, $id: Int!) {
      uploadImage(agent_id: $id, image: $image)
    }
  `;
  const [avatarUpload, { loading: isLoading, error, data }] = useMutation(queryBody);

  const request = (image: File, agentID: number | string) => avatarUpload({ variables: { image, id: agentID } });

  return { request, isLoading, error, data };
};

export default uploadAgentImage;
