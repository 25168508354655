import React from 'react';
import MontlyAccounting from '../../../components/MontlyAccounting/MontlyAccouting';
import { Grid } from '@mui/material';

function MontlyAccountingPage() {
  return (
    <Grid container spacing={2} justifyContent="space-between">
      <Grid item xs={12}>
        <MontlyAccounting />
      </Grid>
    </Grid>
  );
}

export default MontlyAccountingPage;
