import React, { useCallback, useContext, useRef, useState } from 'react';
import { Stack, IconButton, InputAdornment, TextField, Alert } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/iconify';
import DataContext from '../../context/DataProvider/DataContext';
import UserContext from '../../context/UserProvider/UserContext';

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { reloadData } = useContext(DataContext);
  const { login } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const userName = useRef<HTMLInputElement | null>();
  const userPassword = useRef<HTMLInputElement | null>();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = useCallback((e: React.SyntheticEvent) => {
    setIsLoading(true);
    e.preventDefault();
    if (userName.current && userPassword.current) {
      login(userName.current.value, userPassword.current.value)
        .then((isAuthUser) => {
          if (isAuthUser) {
            setIsLoading(false);
            reloadData(); // TODO should goes to UserProvider
            navigate('/');
          } else {
            setIsLoading(false);
            setError(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setError(true);
        });
    }
  }, []);

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {!!error && <Alert severity="error">Es ist ein Fehler aufgetreten</Alert>}

        <TextField inputRef={userName} name="user" label="Benutzername" />

        <TextField
          inputRef={userPassword}
          name="password"
          label="Passwort"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link variant="body2" color="inherit" underline="always">
          Forgot password?
        </Link>
      </Stack> */}

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isLoading}
        sx={{
          bgcolor: 'text.primary',
          color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          '&:hover': {
            bgcolor: 'text.primary',
            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          },
        }}
      >
        Einlogen
      </LoadingButton>
    </form>
  );
}
