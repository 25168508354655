import React from 'react';
import { Grid, Skeleton } from '@mui/material';

function AgentSkeleton() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Skeleton variant="circular" width="100%" height="30%" />
          <Skeleton variant="rectangular" width="80%" height={40} style={{ marginTop: '16px' }} />
        </Grid>

        <Grid item xs={2}>
          {Array.from({ length: 12 }).map((_, index) => (
            <Skeleton key={index} variant="text" width="100%" height={40} style={{ marginBottom: '16px' }} />
          ))}
        </Grid>

        <Grid item xs={2}>
          {Array.from({ length: 7 }).map((_, index) => (
            <Skeleton key={index} variant="text" width="100%" height={40} style={{ marginBottom: '16px' }} />
          ))}
        </Grid>

        <Grid item xs={2}>
          {Array.from({ length: 8 }).map((_, index) => (
            <Skeleton key={index} variant="text" width="100%" height={40} style={{ marginBottom: '16px' }} />
          ))}
        </Grid>

        <Grid item xs={2}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Skeleton key={index} variant="text" width="100%" height={40} style={{ marginBottom: '16px' }} />
          ))}
        </Grid>

        <Grid item xs={2}>
          {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={index} variant="text" width="100%" height={40} style={{ marginBottom: '16px' }} />
          ))}
        </Grid>
      </Grid>

      <Skeleton variant="rectangular" width="100%" height="30%" style={{ marginTop: '32px' }} />
    </>
  );
}

export default AgentSkeleton;
