import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import onlyWhitespaceInString from '../../../../../services/onlyWhitespaceInString';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function validateValue(value: Props['value']): { error: boolean; helperText: string } {
  if (value) {
    if (onlyWhitespaceInString(value)) {
      return {
        error: true,
        helperText: 'Darf nicht nur aus Leerzeichen bestehen',
      };
    }
    if (value.length > 255) {
      return {
        error: true,
        helperText: 'Max 255 Zeichen lang',
      };
    }
  }
  return {
    error: false,
    helperText: '',
  };
}

function TextEditor({ value, onValueChange }: Props) {
  const [helperText, setHelperText] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const valid = validateValue(value);
    setError(valid.error);
    setHelperText(valid.helperText);
  }, [value]);
  return (
    <Box>
      <TextField
        value={value || ''}
        onChange={(event) => onValueChange(event.target.value)}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
}

export default TextEditor;
