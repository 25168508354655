import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

interface Props {
  children: React.ReactNode;
  token: string;
}

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function ErrorMessage({ children, token }: Props) {
  const [isOpenWhenError, setIsOpenWhenError] = React.useState(false);
  const [isOpenWhenInfo, setIsOpenWhenInfo] = React.useState(false);
  const [infoText, setInfoText] = React.useState('');

  return (
    <>
      <Snackbar open={isOpenWhenInfo} autoHideDuration={10000} onClose={() => setIsOpenWhenInfo(false)}>
        <Alert severity="info" elevation={6} variant="filled" onClose={() => setIsOpenWhenInfo(false)}>
          {infoText}
        </Alert>
      </Snackbar>
      <ApolloProvider
        client={
          new ApolloClient({
            cache: new InMemoryCache(),
            link: token
              ? ApolloLink.from([
                  onError(({ graphQLErrors, networkError }) => {
                    if (graphQLErrors) {
                      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
                        // eslint-disable-next-line no-console
                        console.error(
                          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, CODE: ${extensions?.code}`,
                        );

                        if (isJsonString(message)) {
                          const { CODE, MESSAGE }: { CODE: number; MESSAGE: string } = JSON.parse(message);
                          if (CODE === 1000) {
                            setIsOpenWhenInfo(true);
                            setInfoText(MESSAGE);
                          } else {
                            setIsOpenWhenError(true);
                          }
                        } else {
                          setIsOpenWhenError(true);
                        }
                      });
                    }
                    if (networkError) {
                      // eslint-disable-next-line no-console
                      console.error(`[Network error]: ${networkError}`);
                      setIsOpenWhenError(true);
                    }
                    if (!graphQLErrors && !networkError) {
                      // eslint-disable-next-line no-console
                      console.error('Ein unbekannter Fehler');
                    }
                  }),
                  // @ts-expect-error
                  createUploadLink({
                    uri: '/backend',
                    headers: {
                      authorization: `Bearer ${token}`,
                    },
                  }),
                ])
              : ApolloLink.from([]),
          })
        }
      >
        {children}
      </ApolloProvider>
    </>
  );
}
export default ErrorMessage;
