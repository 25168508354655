import React, { useContext, useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { BookingAccount } from '../../../../../types/BookingAccount.types';
import DataContext from '../../../../../context/DataProvider/DataContext';
import onlyWhitespaceInString from '../../../../../services/onlyWhitespaceInString';

function titleIsIdentical(
  inputValue: DataTypeProvider.ValueEditorProps['value'],
  currentValue: string | undefined,
  listOfBookingAccounts: BookingAccount[],
): boolean {
  const nameIsIdentical = false;
  for (let index = 0; index < listOfBookingAccounts.length; index += 1) {
    if (currentValue) {
      if (inputValue === currentValue) {
        return false;
      }
    }
    if (inputValue === listOfBookingAccounts[index].title) {
      return true;
    }
  }
  return nameIsIdentical;
}

function validateValue(
  value: DataTypeProvider.ValueEditorProps['value'],
  initialValue: string | undefined,
  listOfBookingAccounts: BookingAccount[],
): { error: boolean; helperText: string } {
  const nameIsIdentical: boolean = titleIsIdentical(value, initialValue, listOfBookingAccounts);
  if (!value) {
    return {
      error: true,
      helperText: 'Pflichtfeld',
    };
  }
  if (onlyWhitespaceInString(value)) {
    return {
      error: true,
      helperText: 'Darf nicht nur aus Leerzeichen bestehen',
    };
  }
  if (value.length > 255) {
    return {
      error: true,
      helperText: 'Max 255 Zeichen lang',
    };
  }
  if (nameIsIdentical) {
    return {
      error: true,
      helperText: 'Titel ist bereits vorhanden',
    };
  }
  return {
    error: false,
    helperText: '',
  };
}

function TextEditor({ value, onValueChange, disabled }: DataTypeProvider.ValueEditorProps) {
  const { bookingAccount } = useContext(DataContext);
  const [helperText, setHelperText] = useState('');
  const [error, setError] = useState(false);

  const { current: initialValue } = useRef(value);

  useEffect(() => {
    const valid = validateValue(value, initialValue, bookingAccount);
    setError(valid.error);
    setHelperText(valid.helperText);
  }, [value]);

  return (
    <Box>
      <TextField
        disabled={disabled}
        value={value || ''}
        onChange={(event) => onValueChange(event.target.value)}
        error={error}
        helperText={disabled ? '' : helperText}
      />
    </Box>
  );
}

export default TextEditor;
