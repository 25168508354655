import React, { useContext } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import DataContext from '../../../context/DataProvider/DataContext';
import columns from './columnDefinition';
import Table from '../../../components/Table/Table';
import EditTableRowFormDialog from './components/EditTableRowFormDialog';
import BooleanEditor from './components/BooleanEditor';
import BooleanFormatter from './services/booleanFormatter';

function BooleanTypeProvider() {
  return (
    <DataTypeProvider formatterComponent={BooleanFormatter} editorComponent={BooleanEditor} for={['is_default']} />
  );
}

const Provider = [BooleanTypeProvider];

function AllBrachofficeTable() {
  const { brachOffices } = useContext(DataContext);
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState();

  const editingStateColumnExtensions = [
    { columnName: 'id', editingEnabled: false },
    { columnName: 'option', editingEnabled: false },
  ];

  return (
    <>
      <EditTableRowFormDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        rowValue={selectedRow}
      />
      <Table
        row={brachOffices}
        columns={columns}
        defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
        columnExtensions={editingStateColumnExtensions}
        defaultHiddenColumnNames={['domain', 'show_in_export', 'logo', 'title_for_worker_passport']}
        editable
        showAddCommand
        showEditCommand
        dataTypeProvider={Provider}
        TableCommandComponents={{
          add: () => (
            <Box>
              <IconButton
                onClick={() => {
                  setSelectedRow(undefined);
                  setOpen(true);
                }}
              >
                <AddIcon />
              </IconButton>
            </Box>
          ),
        }}
        EditCellForCommand={({ row }) => (
          <Box display="flex" width="100%" justifyContent="center">
            <IconButton
              onClick={() => {
                setSelectedRow(row);
                setOpen(true);
              }}
              title="Bearbeiten"
            >
              <EditIcon />
            </IconButton>
          </Box>
        )}
      />
    </>
  );
}

export default AllBrachofficeTable;
