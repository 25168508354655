import { gql } from '@apollo/client';

const getAllUser = gql`
  query {
    getAllUser {
      id
      username
      email
      enabled
      password
      last_login
      roles
    }
  }
`;
export default getAllUser;
