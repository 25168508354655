import React from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Card, CardContent, Typography } from '@mui/material';
import ItemProject from './ItemProject';
import SendEmailProject from './SendEmailProject';
import AlertMessage from '../../AlertMessage';
import getUserProjectById from '../../../Apollo/queries/getUserProjectById';
import sendAgentToCentralNodeQuery from '../../../Apollo/mutations/sendAgentToCentralNodeQuery';
import { ProjectType } from '../../../types/Project.types';

interface Props {
  id: string;
  agentId: string | number;
  contractId: number | null;
}

function AgentProjects({ id, agentId, contractId }: Props) {
  const { data: getUserProjectData, refetch } = useQuery(getUserProjectById, { variables: { id } });
  const [sendAgentToCentralNode, { data, error }] = useMutation<{ sendAgentsToCentralNode: boolean }>(
    sendAgentToCentralNodeQuery,
  );
  return (
    <>
      {getUserProjectData?.getUsersProjectByID.map((projectItem: ProjectType) => (
        <Card>
          <CardContent>
            <ItemProject
              title={projectItem.project.title}
              date={projectItem.updatedAt}
              status={Boolean(projectItem.updatedAt)}
              refreshDate={() => {
                sendAgentToCentralNode({ variables: { agentID: agentId } }).then((result) => {
                  if (result.data) refetch();
                });
              }}
            />
            {contractId === 3 && (
              <Typography style={{ color: 'gray', fontWeight: 'normal', marginBottom: '5%' }}>
                Keine Passwortgenerierung für Vertragsart "ProHolidays"
              </Typography>
            )}
            <SendEmailProject id={id} isDisabled={!projectItem.updatedAt || contractId === 3} />
          </CardContent>
        </Card>
      ))}
      <AlertMessage
        typeMessage={data?.sendAgentsToCentralNode && !error ? 'success' : 'error'}
        alertMessage={
          data?.sendAgentsToCentralNode && !error ? 'Synchronisation erfolgreich' : 'Synchronisation fehlgeschlagen'
        }
        isAlertMessage={!!data?.sendAgentsToCentralNode}
      />
    </>
  );
}

export default AgentProjects;
