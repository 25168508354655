import React from 'react';
import { Plugin, Getter } from '@devexpress/dx-react-core';

const handleRowsGetter =
  (onRowsUpdate: any) =>
  ({ rows }: any) => {
    setTimeout(() => onRowsUpdate(rows));
    return rows;
  };

function RowsProvider({ onRowsUpdate }: any) {
  const handleRows = handleRowsGetter(onRowsUpdate);
  return (
    <Plugin>
      <Getter name="rows" computed={handleRows} />
    </Plugin>
  );
}

export default RowsProvider;
