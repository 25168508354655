import jwtDecode from 'jwt-decode';
import { User } from '../../types/User.types';

function userLogOut() {
  localStorage.removeItem('token');
  window.location.replace('login');
}

function getCurrentUser(setApolloClientToken?: () => void): User | null {
  const userToken = localStorage.getItem('token');
  if (setApolloClientToken) setApolloClientToken();
  if (userToken) {
    const user: User = jwtDecode(userToken);
    if (user.exp > Math.floor(Date.now() / 1000)) {
      return jwtDecode(userToken);
    }
    localStorage.removeItem('token');
    const win: Window = window;
    // @ts-ignore
    win.location = '/login';
    return null;
  }
  return null;
}

function getToken(): string | null {
  const token = localStorage.getItem('token');
  if (token) {
    return token;
  }
  return null;
}

export { getCurrentUser, userLogOut, getToken };
