import React, { useEffect, useContext } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { useMutation } from '@apollo/client';
import createTaxQuery from '../../../../Apollo/mutations/createTax';
import updateTaxQuery from '../../../../Apollo/mutations/updateTax';
import Table from '../../../../components/Table/Table';
import NumberEditor from './components/NumberEditor';
import TextEditor from './components/TextEditor';
import columns from './columnDefinition';
import commitChanges from './commtChanges';
import DataContext from '../../../../context/DataProvider/DataContext';

const numberColumns = ['percent'];

function NumberTypeProvider() {
  return <DataTypeProvider editorComponent={NumberEditor} for={numberColumns} />;
}

const textColumns = ['code'];

function TextTypeProvider() {
  return <DataTypeProvider editorComponent={TextEditor} for={textColumns} />;
}

const Provider = [TextTypeProvider, NumberTypeProvider];

function AllTaxTable() {
  const { tax, loaderFun } = useContext(DataContext);
  const [createTax] = useMutation(createTaxQuery);
  const [updateTax] = useMutation(updateTaxQuery);

  useEffect(() => {
    loaderFun.loadTax();
  }, []);

  const editingStateColumnExtensions = [{ columnName: 'id', editingEnabled: false }];
  return (
    <Table
      row={tax}
      columns={columns}
      defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
      onCommitChanges={({ added, changed }) =>
        commitChanges({ added, changed, rows: tax, loaderFun, createTax, updateTax })
      }
      columnExtensions={editingStateColumnExtensions}
      dataTypeProvider={Provider}
      editable
      showAddCommand
      showEditCommand
    />
  );
}

export default AllTaxTable;
