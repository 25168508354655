import React, { useState, useContext, useMemo, useCallback, useEffect, useRef } from 'react';
import {
  Button,
  Table,
  TableContainer,
  Paper,
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  LinearProgress,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { useCSVReader } from 'react-papaparse';
import WarningIcon from '@mui/icons-material/Warning';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReceiptInput from '../../../types/ReceiptInput.types';
import UserContext from '../../../context/UserProvider/UserContext';
import DataContext from '../../../context/DataProvider/DataContext';
import createMultiReceipts from '../../../Apollo/mutations/createMultiReceipts';
import { Agent, AgentStatus } from '../../../types/Agent.types';
import getPayDateForReceipts from '../../../services/getPayDateForReceipts';

function StyledTableCell({ children }: { children: React.ReactNode }) {
  return <TableCell sx={{ backgroundColor: 'grey', color: 'white', fontSize: 14 }}>{children}</TableCell>;
}

function ImportShopReceipts() {
  const [openDialog, setOpenDialog] = useState(false);
  const { bookingAccount, dateLastPayroll, agents } = useContext(DataContext);
  const sonnstigeKosten = useMemo(
    () => bookingAccount.find((itemBooking) => itemBooking.booking_account_nr === 860251)?.id,
    [bookingAccount],
  );

  const user = useContext(UserContext);
  const { CSVReader } = useCSVReader();
  const [CSVData, setCSVData] = useState({ data: [[]] });
  const count = useRef<number>(0);

  const [createReceiptFn, { data, loading }] = useMutation<{ createMultiReceipts: Boolean }>(createMultiReceipts);

  const AgentFilter = useCallback(
    (agent: Agent, agentCorpMail: string) =>
      agentCorpMail === agent.email_corp && agent.status.id === AgentStatus.active,
    [],
  );
  interface FromReceiptProps {
    agentCorpMail: string;
    price: number;
    number: number;
    description: string;
  }

  const fromReceipt = ({ agentCorpMail, price, number, description }: FromReceiptProps): ReceiptInput | undefined => {
    const agent = agents.find((itemAgent) => AgentFilter(itemAgent, agentCorpMail));
    if (!agent || !sonnstigeKosten) return undefined;

    const formedReceipt: ReceiptInput = {
      author_id: user.id,
      agent_id: agent.id,
      booking_account_id: sonnstigeKosten,
      tax_id: 2,
      price: price * number,
      description,
      month: getPayDateForReceipts(dateLastPayroll).getMonth(),
      year: getPayDateForReceipts(dateLastPayroll).getYear(),
      type: 'IncomeReceipt',
    };
    return formedReceipt;
  };

  const handleClose = useCallback(() => {
    setCSVData({ data: [[]] });
    setOpenDialog(false);
  }, []);

  useEffect(() => {
    if (loading) setOpenDialog(true);
  }, [loading, data]);

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        setCSVData(results);
      }}
    >
      {({ getRootProps, ProgressBar, acceptedFile }: any) => (
        <>
          <Dialog open={openDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {loading && <DialogTitle id="alert-dialog-title">Beleg werden Erstellt</DialogTitle>}
            {!loading && <DialogTitle id="alert-dialog-title">Verarbeitung Abgeschlossen</DialogTitle>}
            <DialogContent>
              <Box justifyContent="center">
                {loading && (
                  <Box sx={{ width: '100%' }}>
                    <LinearProgress color="secondary" />
                    in Verarbeitung...
                  </Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={handleClose} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>

          <Box display="flex">
            {!sonnstigeKosten && (
              <Box style={{ color: 'red' }}>
                Achtung das Konto für Manuelle Einnahmen kann nicht ermittel werden ein Import ist nicht möglich
              </Box>
            )}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Button variant="outlined" {...getRootProps()}>
              Datei wählen
            </Button>
            <Button
              disabled={!acceptedFile || CSVData.data.length === 1 || !sonnstigeKosten}
              variant="outlined"
              onClick={async () => {
                const receipts: ReceiptInput[] = [];
                CSVData.data.map((row, index) => {
                  if (index === 0 || row.length <= 1) return null;

                  const receipt = fromReceipt({
                    agentCorpMail: row[1],
                    price: +parseFloat(row[18]).toFixed(2),
                    number: row[16],
                    description: `${row[17]}`,
                  });
                  if (row[9]) {
                    const shippingCoast = fromReceipt({
                      agentCorpMail: row[1],
                      price: +parseFloat(row[9]).toFixed(2),
                      number: 1,
                      description: `Versandkosten`,
                    });
                    if (shippingCoast) {
                      receipts.push(shippingCoast);
                    }
                  }

                  if (receipt) {
                    receipts.push(receipt);
                  }
                  return null;
                });

                await createReceiptFn({ variables: { input: receipts } });
              }}
            >
              Belege erstellen
            </Button>
          </Box>
          <ProgressBar />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Nr.</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Empfänger</StyledTableCell>
                  <StyledTableCell>E-Mail</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Summe Netto</StyledTableCell>
                  <StyledTableCell>Versand</StyledTableCell>
                  <StyledTableCell>MwSt.</StyledTableCell>
                  <StyledTableCell>Gesamt</StyledTableCell>
                  <StyledTableCell>Anzahl</StyledTableCell>
                  <StyledTableCell>Stk. Preis</StyledTableCell>
                  <StyledTableCell>Beschreibung</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CSVData.data.map((row, index) => {
                  if (index === 0 || row.length <= 1) return null;
                  if (index >= count.current) count.current += 1;
                  const agentCorpMail = row[1];
                  const orderNumber = row[16];
                  const price = row[18];
                  const description = row[17];
                  const agent = agents.find((itemAgent) => AgentFilter(itemAgent, agentCorpMail));
                  const isWarning = !(agentCorpMail && orderNumber && description && price && agent);

                  return (
                    <Tooltip
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${row[0]}${index}`}
                      title={isWarning ? 'Informationen sind inkorrekt, Beleg wird nicht erstellt' : ''}
                    >
                      {/* eslint-disable-next-line react/no-array-index-key */}
                      <TableRow style={{ background: isWarning ? 'orange' : '' }} key={`${row[0]}${index}`}>
                        <TableCell align="left">
                          <Box display="flex" alignItems="center">
                            {index}.{isWarning && <WarningIcon />}
                          </Box>
                        </TableCell>
                        <TableCell align="left">{row[0]}</TableCell>
                        <TableCell align="left">{row[51]}</TableCell>
                        <TableCell align="left">{row[34]}</TableCell>
                        <TableCell align="left">{row[1]}</TableCell>
                        <TableCell align="left">{row[2]}</TableCell>
                        <TableCell align="left">{row[8]}</TableCell>
                        <TableCell align="left">{row[9]}</TableCell>
                        <TableCell align="left">{row[10]}</TableCell>
                        <TableCell align="left">{row[11]}</TableCell>
                        <TableCell align="left">{row[16]}</TableCell>
                        <TableCell align="left">{row[18]}</TableCell>
                        <TableCell align="left">{row[17]}</TableCell>
                      </TableRow>
                    </Tooltip>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </CSVReader>
  );
}

export default ImportShopReceipts;
