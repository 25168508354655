import React from 'react';
import { Button, Grid } from '@mui/material';

function BrachofficeBtnContainer({ brachofficeData, setBrancheOfficeFilter, allFilterReset }) {
  return (
    <Grid container direction="row-reverse">
      <Button variant="contained" disableRipple onClick={allFilterReset}>
        FILTER ZURÜCKSETZEN
      </Button>
    </Grid>
  );
}

export default BrachofficeBtnContainer;
