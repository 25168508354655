import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Tab,
  Tabs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  Select,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import PropTypes from 'prop-types';
import addServiceToAgent from '../services/addServicesToAgent';
import { SERVICESTYPE } from '../../../../../services/config/CONST';
import { Service } from '../../../../../types/Service.types';

function TabPanel(props: { children: React.ReactNode; index: any; value: any }) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

type Props = {
  allServices: Service[];
  agent_id: string | undefined;
  refreshServicesById: () => void;
};

function DialogAddServiceToAgent({ allServices, agent_id, refreshServicesById }: Props) {
  const [open, setOpen] = useState(false);
  const [servicesList, setServicesList] = useState<Service[]>([]);
  const [currentService, setCurrentService] = useState<Service | undefined>();
  const [singleService, setSingleService] = useState<Service[]>([]);
  const [monthlyService, setMonthlyService] = useState<Service[]>([]);
  const [yearlyService, setYearlyService] = useState<Service[]>([]);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const singleArray: Service[] = [];
    const monthlyArray: Service[] = [];
    const yearlyArray: Service[] = [];

    allServices.map((item) => {
      if (item.type === SERVICESTYPE.MonthlyService) {
        monthlyArray.push(item);
      } else if (item.type === SERVICESTYPE.SingleService) {
        singleArray.push(item);
      } else if (item.type === SERVICESTYPE.YearlyService) {
        yearlyArray.push(item);
      }
      return {};
    });

    setSingleService(singleArray);
    setMonthlyService(monthlyArray);
    setYearlyService(yearlyArray);
    setServicesList(allServices);
  }, [allServices]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange1 = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedService = servicesList.find((item) => item.id === parseInt(event.target.value, 10));
    setCurrentService(selectedService);
  };

  const handleAddServiceToAgent = async () => {
    const response = await addServiceToAgent(currentService, agent_id);
    setOpen(false);
    if (response.data.addServiceToAgent) {
      refreshServicesById();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="secondary" variant="outlined" title="neue Leistung hinzufügen" onClick={handleClickOpen}>
        +
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>neue Leistung hinzufügen</DialogTitle>
        <DialogContent>
          <Tabs value={value} onChange={handleChange1} indicatorColor="primary" textColor="primary">
            <Tab label="Einmalig" />
            <Tab label="Monatlich" />
            <Tab label="Jährlich" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Box display="flex" flexWrap="wrap">
              <FormControl
                variant="filled"
                sx={{
                  margin: 1,
                  minWidth: '90%',
                }}
              >
                <InputLabel htmlFor="filled-age-native-simple">wählen Sie eine Leistung</InputLabel>
                <Select
                  native
                  value={currentService?.id?.toString() || singleService[0]?.id?.toString() || ''}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'filled-age-native-simple',
                  }}
                >
                  {singleService.map((service) => (
                    <option key={service.id} value={service.id.toString()}>
                      {service.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FormControl
              variant="filled"
              sx={{
                margin: 1,
                minWidth: '90%',
              }}
            >
              <InputLabel htmlFor="filled-age-native-simple">wählen Sie eine Leistung</InputLabel>
              <Select
                native
                value={currentService?.id?.toString() || monthlyService[0]?.id?.toString() || ''}
                onChange={handleChange}
                inputProps={{
                  name: 'age',
                  id: 'filled-age-native-simple',
                }}
              >
                {monthlyService.map((service) => (
                  <option key={service.id} value={service.id.toString()}>
                    {service.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box display="flex" flexWrap="wrap">
              <FormControl
                variant="filled"
                sx={{
                  margin: 1,
                  minWidth: '90%',
                }}
              >
                <InputLabel htmlFor="filled-age-native-simple">wählen Sie eine Leistung</InputLabel>
                <Select
                  native
                  value={currentService?.id?.toString() || yearlyService[0]?.id?.toString() || ''}
                  onChange={handleChange}
                  inputProps={{
                    name: 'age',
                    id: 'filled-age-native-simple',
                  }}
                >
                  {yearlyService.map((service) => (
                    <option key={service.id} value={service.id.toString()}>
                      {service.title}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddServiceToAgent} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogAddServiceToAgent;
