import { url } from './config/config';

function сheckingQueryExecutionError(responceBody) {
  if (responceBody.errors) {
    const array = [];
    responceBody.errors.forEach((element) => {
      array.push(element.message.split('::Error:').splice(-1)[0]);
    });
    return { error: array };
  }
  return responceBody.data;
}

function requestToServer(typeQuery, bodys, variables) {
  try {
    if (typeQuery === 'query') {
      const queryBody = `{"query":"query{ ${bodys.toString()} }","variables":{},"operationName":null}`;
      return fetch(url, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        json: true,
        body: queryBody,
      })
        .then((responce) => responce.json())
        .then((result) => сheckingQueryExecutionError(result))
        .catch((error) => {
          console.error('requestToServer data Error:', error);
          return [];
        });
    }
    if (typeQuery === 'mutation') {
      return fetch(url, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          query: bodys,
          variables: { variables },
        }),
      })
        .then((responce) => responce.json())
        .then((result) => сheckingQueryExecutionError(result))
        .catch((error) => {
          console.error('requestToServer data Error:', error);
          return [];
        });
    }
    return Promise.resolve([]);
  } catch (error) {
    console.error(`Method requestToServer error:`, error);
    return Promise.resolve([]);
  }
}

export default requestToServer;
