import { AgentInputType } from '../AgentInputType.types';

const InitDate = new Date('01.01.1990').toISOString().split('T');

const AgentInput: AgentInputType = {
  name: '',
  surname: '',
  birthday: InitDate[0],
  status_id: 0,
  is_male: 0,
  agent_id: 0,

  phone: null,
  mobile: null,
  fax: null,
  email_corp: null,
  email_private: '',

  rank_id: 0,
  level_id: null,
  branch_office_id: 0,
  contract_type_id: null,
  jackplus_id: 0,
  myjack_login: null,

  address: '',
  zip: '',
  city: '',
  country_id: 0,

  address2: null,
  zip2: null,
  city2: null,
  country2_id: null,

  tax_nr: null,
  ust_nr: null,
  iban: null,
  bic: null,
  business_owner: null,
  business_registration_date: null,
  start_date: null,
  is_small_business: 0,

  dismissed_at: null,
  dismissal_reason: null,
  manager_id: null,
  photo: null,
  notes: null,
  agent_account_nr: 0,
  reverse_charge: null,
  branch_office_email_postfix: null,
  bank_name: null,
};

export default AgentInput;
