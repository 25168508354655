function setStartDate(date) {
  const arraySymbols = date.split('-');
  if (Number(arraySymbols[1]) < 12) {
    arraySymbols[1] =
      Number(arraySymbols[1]) + 1 < 10
        ? `0${String(Number(arraySymbols[1]) + 1)}`
        : String(Number(arraySymbols[1]) + 1);
    arraySymbols[2] = '01';
  } else {
    arraySymbols[0] = String(Number(arraySymbols[0]) + 1);
    arraySymbols[1] = '01';
  }
  const startDate = arraySymbols.join('-');
  return startDate;
}

export default setStartDate;
