import React from 'react';
import TextField from '@mui/material/TextField';

interface Props {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

function TextEditor({ value, onValueChange }: Props) {
  return (
    <TextField
      value={value || ''}
      onChange={(event) => onValueChange(event.target.value)}
      error={!value}
      helperText={value ? '' : 'Incorrect entry'}
    />
  );
}

export default TextEditor;
