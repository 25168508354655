import React, { useCallback, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import getAllInteressents from '../../../../Apollo/queries/getAllInteressents';
import Maintable from '../../../../components/Table';
import columnsDef from './columnsdefenition';
import DataContext from '../../../../context/DataProvider/DataContext';
import { useLoadingContext } from '../../../../context/LoadingProvider/LoadingContext';

const InitDate = new Date('01.01.1990').toISOString().split('T');
const InteressentInitValue = {
  id: null,
  name: '',
  surname: '',
  birthday: InitDate[0],
  is_male: 0,
  agent_id: null,
  phone: null,
  mobile: null,
  email_private: '',
  contract_type_id: null,
  address: null,
  zip: null,
  city: null,
  country_id: null,
  notes: null,
  branch_office_id: null,
  __typename: '',
};

export default function AllInteressentsTable() {
  const { brachOffices, country, contractType } = useContext(DataContext);
  const { data, loading: loadingAllInteressents } = useQuery(getAllInteressents);
  const navigate = useNavigate();
  const { setLoading } = useLoadingContext();
  const row = data ? data.getInteresents : [InteressentInitValue];

  setLoading(loadingAllInteressents);

  const TableRow = useCallback(
    ({ row, children, tableRow }: Table.DataRowProps) => (
      <Table.Row
        key={Math.random()}
        tableRow={tableRow}
        row={row}
        // eslint-disable-next-line react/no-children-prop
        children={children}
        onClick={() => {
          navigate(`/interessent/${row.id}`);
        }}
        style={{ cursor: 'pointer' }}
      />
    ),
    [],
  );
  const columns = columnsDef(brachOffices, country, contractType);
  return (
    <Maintable
      row={row}
      columns={columns}
      defaultColumnWidths={columns.map((column) => ({ columnName: column.name, width: 'auto' }))}
      rowComponent={TableRow}
    />
  );
}
