import { gql } from '@apollo/client';

const createUser = gql`
  mutation ($input: CreatUser!) {
    registUser(input: $input) {
      username
      email
      enabled
      password
      roles
    }
  }
`;
export default createUser;
