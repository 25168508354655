import React, { useState, useEffect, ComponentType, useCallback, useMemo } from 'react';
import Paper from '@mui/material/Paper';
import {
  Grid,
  TableColumnVisibility,
  Table as DevExpressTable,
  TableHeaderRow,
  PagingPanel,
  ColumnChooser,
  SearchPanel,
  Toolbar,
  TableEditRow,
  TableEditColumn,
  TableFilterRow,
  TableGroupRow,
  TableColumnResizing,
  ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  SearchState,
  Sorting,
  EditingState,
  ChangeSet,
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Box, Button, IconButton, Input, MenuItem, Select, TableCell, withStyles } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddButton from '../../pages/Options/BrachofficePage/components/AddButton';
import EditButton from '../../pages/Options/BrachofficePage/components/EditButton';
import DeleteButton from '../../pages/Options/BrachofficePage/components/DeleteButton';
import CommitButton from '../../pages/Options/BrachofficePage/components/CommitButton';
import { Service } from '../../types/Service.types';
import RowsProvider from '../../pages/AgentTable/components/RowsProvider';
import { DEFAULTTABLEPAGESIZE } from '../../services/config/CONST';

function ToolbarRoot({ ...restProps }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Toolbar.Root {...restProps} />;
}

interface LookupEditCellBaseProps extends TableEditRow.CellProps {
  availableColumnValues: any;
  classes: any;
}
interface CancelButtonProps {
  onExecute: () => void;
}
function CancelButton({ onExecute }: CancelButtonProps) {
  return (
    <IconButton color="secondary" onClick={onExecute} title="Cancel changes">
      <CancelIcon />
    </IconButton>
  );
}

function LookupEditCellBase({
  availableColumnValues,
  value,
  onValueChange,
  classes,
}: LookupEditCellBaseProps): JSX.Element {
  return (
    <TableCell className={classes.lookupEditCell}>
      <Select
        value={value}
        defaultValue=""
        onChange={(event) => onValueChange(event.target.value)}
        MenuProps={{
          className: classes.selectMenu,
        }}
        input={<Input classes={{ root: classes.inputRoot }} />}
      >
        {availableColumnValues.map((item: any) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </TableCell>
  );
}

// TODO What the code in the commend do

// const global = { title: ['Yes', 'No'] };

// const availableValues = {
//   is_default: global.title,
//   show_in_export_xml: global.title,
// };

function EditCell(props: TableEditRow.CellProps) {
  const { column, value, editingEnabled, row } = props;
  // TODO What the code in the commend do

  // @ts-ignore
  // const availableColumnValues = availableValues[column?.name];
  // if (availableColumnValues) {
  //   return <LookupEditCell {...props} availableColumnValues={availableColumnValues} />;
  // }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <TableEditRow.Cell {...props} />;
}

const getRowId = (row: any): string | number => row.id;

interface Props {
  row: any[];
  columns: any[];
  pageSize?: number;
  pageSizes?: number[];
  defaultHiddenColumnNames?: string[];
  defaultColumnWidths?: any[];
  onCommitChanges?: (changes: ChangeSet) => void;
  columnExtensions?: EditingState.ColumnExtension[];
  dataTypeProvider?: (() => JSX.Element)[];
  // TODO implement commandComponent to Table
  commandComponent?: ComponentType<TableEditColumn.CommandProps>;
  EditCellForCommand?: ComponentType<TableEditColumn.CellProps>;
  rowComponent?: ComponentType<DevExpressTable.DataRowProps>;
  FilterRow?: boolean;
  editable?: boolean;
  showAddCommand?: boolean;
  showEditCommand?: boolean;
  showDeleteCommand?: boolean;
  groupingTable?: boolean;
  groupingCollumName?: any[];
  filteringState?: any[];
  sortingProp?: any[];
  forServicesById?: { tableName: string; services: Service[]; agent_id: string | undefined; refresh: () => void };
  exporting?: {
    all?: {
      export: boolean;
      refConst: any | null;
      rows: any[];
      columns: any[];
      onSave: (wokbook: any) => void;
      exportFunk: () => any;
    };
    filter?: {
      export: boolean;
      refConst: any | null;
      columns: any[];
      onSave: (wokbook: any) => void;
      exportFunk: () => any;
    };
  };
  TableCommandComponents?: any;
}

export const CommandComponents = {
  add: AddButton,
  edit: EditButton,
  delete: DeleteButton,
  commit: CommitButton,
  cancel: CancelButton,
};

function Table({
  row,
  columns,
  pageSize = DEFAULTTABLEPAGESIZE.pageSize,
  pageSizes = [5, 10, 15, 20, 0],
  defaultHiddenColumnNames = [],
  defaultColumnWidths = [],
  onCommitChanges,
  columnExtensions,
  dataTypeProvider = [],
  FilterRow = false,
  editable = false,
  showAddCommand = false,
  showEditCommand = false,
  showDeleteCommand = false,
  EditCellForCommand,
  groupingTable = false,
  groupingCollumName,
  forServicesById,
  filteringState = [],
  sortingProp,
  rowComponent,
  exporting,
  TableCommandComponents,
}: Props): JSX.Element {
  // TODO implement sorting to Table
  const [sorting, setSorting] = useState<Sorting[]>([{ columnName: 'ID', direction: 'asc' }]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSizeState, setPageSize] = useState<number>();
  const [exportedRows, setExportedRows] = useState([]);

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize]);

  const handleSorting = (sorting: Sorting[]) => {
    setSorting(sorting);
  };

  const Command = useCallback(({ id, onExecute }: TableEditColumn.CommandProps) => {
    const CommandButton = TableCommandComponents ? TableCommandComponents[id] : CommandComponents[id];
    return <CommandButton onExecute={onExecute} forServicesById={forServicesById} />;
  }, []);

  const DefaultRow = useCallback(
    ({ row, children, tableRow }: DevExpressTable.DataRowProps) => (
      <DevExpressTable.Row
        key={Math.random()}
        tableRow={tableRow}
        row={row}
        // eslint-disable-next-line react/no-children-prop
        children={children}
      />
    ),
    [],
  );

  const exportMenu = useCallback(
    () => (
      <Box display="flex" flexDirection="column">
        {exporting?.all?.export && <Button onClick={exporting?.all?.exportFunk}>Alle</Button>}
        {exporting?.filter?.export && <Button onClick={exporting?.filter?.exportFunk}>Filter</Button>}
      </Box>
    ),
    [],
  );
  const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);
  const [resizingMode, setResizingMode] = useState('nextColumn');
  const [hiddenColumnNames, setHiddenColumnNames] = useState([]);

  const resetWidths = () => {
    setColumnWidths(defaultColumnWidths);
  };

  // Memoize visibleColumns to prevent unnecessary re-renders
  const visibleColumns = useMemo(
    () =>
      /* @ts-ignore */
      columns.filter((column) => !hiddenColumnNames.includes(column.name)),
    [columns, hiddenColumnNames],
  );

  return (
    // For the correct display of the tables, the position of the table extensions must be observed
    <Paper>
      <Grid rows={row} columns={columns} getRowId={getRowId}>
        <SearchState defaultValue="" />
        <FilteringState defaultFilters={filteringState} />
        <SortingState
          defaultSorting={sortingProp || [{ columnName: 'exported_at', direction: 'desc' }]}
          // sorting={sortingProp}
          onSortingChange={handleSorting}
        />
        {groupingTable && <GroupingState grouping={groupingCollumName} />}
        {groupingTable && <IntegratedGrouping />}
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSizeState}
          onPageSizeChange={setPageSize}
        />
        <IntegratedFiltering />
        <RowsProvider onRowsUpdate={setExportedRows} />
        <IntegratedPaging />
        <IntegratedSorting />
        <EditingState onCommitChanges={onCommitChanges || (() => {})} columnExtensions={columnExtensions} />
        <DevExpressTable rowComponent={rowComponent || DefaultRow} />
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={setColumnWidths}
          resizingMode={resizingMode}
        />

        <TableHeaderRow showSortingControls />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          hiddenColumnNames={hiddenColumnNames}
          /* @ts-ignore */
          onHiddenColumnNamesChange={setHiddenColumnNames}
        />
        {dataTypeProvider.map((item) => item())}
        {FilterRow && <TableFilterRow />}
        <Toolbar rootComponent={ToolbarRoot} />
        <SearchPanel />
        <ColumnChooser />
        <PagingPanel pageSizes={pageSizes} />
        {editable && <TableEditRow cellComponent={EditCell} />}
        {editable && (
          <TableEditColumn
            // eslint-disable-next-line react/jsx-props-no-spreading
            cellComponent={EditCellForCommand || TableEditColumn.Cell}
            showAddCommand={showAddCommand}
            showEditCommand={showEditCommand}
            showDeleteCommand={showDeleteCommand}
            commandComponent={Command}
          />
        )}
        {groupingTable && <TableGroupRow />}
        {/* {exporting?.filter?.export && <ExportPanel menuItemComponent={exportMenu} />} */}
        {exporting?.all?.export && <ExportPanel menuItemComponent={exportMenu} />}
      </Grid>
      {exporting?.all?.export && (
        <GridExporter
          ref={exporting.all.refConst}
          rows={exporting.all.rows}
          columns={visibleColumns}
          onSave={exporting.all.onSave}
        />
      )}
      {exporting?.filter?.export && (
        <GridExporter
          ref={exporting.filter.refConst}
          rows={exportedRows}
          columns={visibleColumns}
          onSave={exporting.filter.onSave}
        />
      )}
    </Paper>
  );
}

export default Table;
