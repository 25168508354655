import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Box, Stack, Alert } from '@mui/material';
import saveAs from 'file-saver';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import getAllServicesQuery from '../../Apollo/queries/getAllServicesQuery';
import { Service } from '../../types/Service.types';
import ServicesInit from '../../types/initValues/Services.init';
import { MONTHS } from '../../services/config/CONST';
import ServicesSelect from '../../pages/Reports/components/ServicesSelect';
import getAllAgentsByServiceID from '../../Apollo/queries/getAllAgentsByServiceID';
import Table from '../Table';
import columns from './columnsDef';
import LoadingDialog from '../LoadingDialog';

// @ts-expect-error
const onSaveXlsx = (workbook) => {
  // @ts-expect-error
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
  });
};

// @ts-expect-error
const onSaveFilterXlsx = (workbook) => {
  // @ts-expect-error
  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'FilterDataGrid.xlsx');
  });
};

interface Prop {
  booking_account: string;
  tax: string;
  fixed_start_month: string | undefined | number | null;
  default_price: number;
  id: number;
  title: string;
  type: string;
}

const serviceInit: Prop = {
  booking_account: 'Init Service',
  default_price: 33,
  fixed_start_month: null,
  id: 1,
  tax: '19%',
  title: 'Init Service',
  type: 'MonthlyService',
};

function ServicesReport() {
  const { data } = useQuery(getAllServicesQuery);
  const [service, setService] = useState(serviceInit);
  const {
    data: getAllAgentsByServiceIDData,
    error,
    loading,
  } = useQuery(getAllAgentsByServiceID, {
    variables: { service_id: service.id },
  });

  const allServices = (service: Service[]) => {
    const servicesData: Prop[] = [];
    if (service) {
      service.map((serviceItem) => {
        const normServiceData = {
          ...serviceItem,
          booking_account: serviceItem.booking_account.title,
          tax: `${serviceItem.tax.percent}% : ${serviceItem.tax.code}`,
          fixed_start_month: serviceItem.fixed_start_month,
        };
        MONTHS.map((month) => {
          if (month.id === normServiceData.fixed_start_month) {
            normServiceData.fixed_start_month = month.title;
          }
          return null;
        });
        servicesData.push(normServiceData);
        return null;
      });
    }
    return servicesData;
  };

  const row = allServices(data ? data?.getAllServices : [ServicesInit]);

  useEffect(() => {
    if (row.length > 0 && service.id === serviceInit.id) {
      setService(row[0]);
    }
  }, [data]);

  const changeReceipt = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: any;
    }>,
  ) => {
    const { value } = event.target;
    const selectedService = row.find((serviceItem) => serviceItem.id === value);
    if (selectedService) {
      setService(selectedService);
    }
  };

  const refExportApp = useRef<typeof GridExporter>(null);
  const startExport = useCallback(() => {
    refExportApp.current?.exportGrid();
  }, [refExportApp]);

  const refExportFilter = useRef<typeof GridExporter>(null);
  const startFilterExport = useCallback(() => {
    refExportFilter.current?.exportGrid({ selectedOnly: true });
  }, [refExportFilter]);

  return (
    <Stack spacing={2}>
      <ServicesSelect service={service} servicesList={row} changeFunc={changeReceipt} />
      {error ? (
        <Alert variant="filled" severity="info">
          {error?.message}
        </Alert>
      ) : getAllAgentsByServiceIDData ? (
        <Table
          FilterRow
          row={getAllAgentsByServiceIDData.getAllAgentsByServiceID}
          columns={columns}
          exporting={{
            all: {
              export: true,
              refConst: refExportApp,
              rows: getAllAgentsByServiceIDData.getAllAgentsByServiceID,
              columns,
              onSave: onSaveXlsx,
              exportFunk: startExport,
            },
            filter: {
              export: true,
              refConst: refExportFilter,
              columns,
              onSave: onSaveFilterXlsx,
              exportFunk: startFilterExport,
            },
          }}
        />
      ) : (
        <LoadingDialog open={loading} />
      )}
    </Stack>
  );
}

export default ServicesReport;
