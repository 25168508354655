function lastDismissedDay(dismissedDate: string | undefined) {
  if (dismissedDate) {
    const date = new Date(dismissedDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    const prmDismissedDate = `${year}-${month}-${lastDayOfMonth}`;
    return prmDismissedDate;
  }
  return null;
}

export default lastDismissedDay;
