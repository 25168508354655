export default {
  agent_id: 'Berater ID',
  name: 'Name',
  surname: 'Vorname',
  business_owner: 'Inhaber (falls abweichend)',
  contract_type_title: 'Vertragsart',
  start_date: 'Start',
  level_title: 'Level',
  manager_surname: 'Teamleiter',
  birthday: 'Geburtstag',
  address: 'Straße / Hausnummer',
  zip: 'PLZ',
  city: 'Stadt',
  country_title: 'Land',
  address2: 'Rechnungsadresse (falls abweichend)',
  email_corp: 'Emailadresse',
  phone: 'Telefonmmer',
  mobile: 'Handynummer',
  fax: 'Faxnummer',
  email_private: 'Private Emailadresse',
  tax_nr: 'Steuer ID',
  ust_nr: 'Ust ID',
  zip2: 'PLZ (Rechnungsanschrift)',
  city2: 'Stadt (Rechnungsanschrift)',
  country2_title: 'Land (Rechnungsanschrift)',
  status_title: 'Status',
  dismissal_reason: 'Kündigungsgrund',
  dismissed_at: 'Kündigungsdatum',
  rank_title: 'Rang',
  is_male: 'Geschlecht',
  branch_office_title: 'Marke',
  is_small_business: 'Besteuerungsart',
  bank_name: 'Bank',
  iban: 'IBAN',
  bic: 'BIC',
  jackplus_id: 'myJACK Unit',
  agent_account_nr: 'Kreditorennummer',
  business_registration_date: 'Gewerbe Anmeldedatum',
  statusID: 'statusID',
  booking_account_id: 'Buchungskonto',
  tax_id: 'Steuersatz',
  price: 'Betrag',
  description: 'Beschreibung',
  exported_at: 'Exportiert am',
  myjack_login: 'myJACK Login',
};
