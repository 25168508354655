import { createContext } from 'react';
import axios from 'axios';
import CommunicationContextValues from './CommunicationContextValues.types';

export const defaultValues: CommunicationContextValues = {
  isLoading: false,
  request: axios.request,
};

export default createContext<CommunicationContextValues>(defaultValues);
