import React from 'react';

interface Props {
  value: string | number;
}
function BooleanFormatter({ value }: Props) {
  return <>{value ? 'Ja' : 'Nein'}</>;
}

export default BooleanFormatter;
