import { gql } from '@apollo/client';

const createServices = gql`
  mutation ($variables: ServiceInput!) {
    createService(input: $variables) {
      id
      title
      default_price
      type
      tax {
        id
        code
        percent
      }
      fixed_start_month
      booking_account {
        id
        title
        booking_account_nr
        external_reference
      }
    }
  }
`;

export default createServices;
