import { gql } from '@apollo/client';

const updateServices = gql`
  mutation ($variables: ServiceUpdate!, $id: Int!) {
    updateService(input: $variables, id: $id) {
      id
      title
      default_price
      type
      tax {
        id
        code
        percent
      }
      fixed_start_month
      booking_account {
        id
        title
        booking_account_nr
        external_reference
      }
    }
  }
`;
export default updateServices;
