import React from 'react';
import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Container, Stack, Typography } from '@mui/material';
import MotionContainer from '../../components/animate/MotionContainer';
import { varBounce } from '../../components/animate/variants/bounce';
import { PageNotFoundIllustration } from '../../components/illustrations';
import Header from '../../components/SiteLayout/header';

export default function Page404() {
  return (
    <>
      <Header />
      <Container component="main">
        <Stack
          sx={{
            py: 12,
            m: 'auto',
            maxWidth: 400,
            minHeight: '100vh',
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Helmet>
            <title> 404 Page Not Found </title>
          </Helmet>

          <MotionContainer>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Die Seite wurde nicht gefunden
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>
                Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check
                your spelling.
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <PageNotFoundIllustration
                sx={{
                  height: 260,
                  my: { xs: 5, sm: 10 },
                }}
              />
            </m.div>

            <Button component={RouterLink} to="/" size="large" variant="contained">
              Go to Home
            </Button>
          </MotionContainer>
        </Stack>
      </Container>
    </>
  );
}
