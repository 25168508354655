import { Column } from '@devexpress/dx-react-grid';
import propertyOf from '../../../services/propertyOf';
import { Agent } from '../../../types/Agent.types';
import columnNames from '../i18n/columnNames';

// @ts-expect-error
const getTitle = (row: Agent, columnName: keyof Agent | string) => row[columnName]?.title;

const getTitelSmallBusiness = (row: Agent) =>
  row.is_small_business === 1 ? 'Kleinunternehmerregelung' : 'Regelbesteuerung';

const getFullBillingAdress = (row: Agent) => {
  if (row.address2 === null || row.zip2 === null || row.city2 === null || row.country2 === null) {
    return '';
  }
  return `${row.address2} , ${row.zip2} , ${row.city2} , ${row.country2.title}`;
};

const columns: Column[] = [
  { name: propertyOf<Agent>('agent_id'), title: columnNames.agent_id },
  { name: propertyOf<Agent>('name'), title: columnNames.surname },
  { name: propertyOf<Agent>('surname'), title: columnNames.name },
  { name: propertyOf<Agent>('business_owner'), title: columnNames.business_owner },
  {
    name: `${propertyOf<Agent>('contract_type')}`,
    title: columnNames.contract_type_title,
    getCellValue: getTitle,
  },
  { name: propertyOf<Agent>('start_date'), title: columnNames.start_date },
  {
    name: propertyOf<Agent>('level'),
    title: columnNames.level_title,
    getCellValue: getTitle,
  },
  {
    name: propertyOf<Agent>('manager'),
    title: columnNames.manager_surname,
    // @ts-expect-error
    getCellValue: (row: Agent, columnName: keyof Agent) => row[columnName]?.surname,
  },
  { name: propertyOf<Agent>('birthday'), title: columnNames.birthday },
  { name: propertyOf<Agent>('address'), title: columnNames.address },
  { name: propertyOf<Agent>('zip'), title: columnNames.zip },
  { name: propertyOf<Agent>('city'), title: columnNames.city },
  { name: propertyOf<Agent>('country'), title: columnNames.country_title, getCellValue: getTitle },
  { name: propertyOf<Agent>('address2'), title: columnNames.address2, getCellValue: getFullBillingAdress },
  { name: propertyOf<Agent>('email_corp'), title: columnNames.email_corp },
  { name: propertyOf<Agent>('phone'), title: columnNames.phone },
  { name: propertyOf<Agent>('mobile'), title: columnNames.mobile },
  { name: propertyOf<Agent>('fax'), title: columnNames.fax },
  { name: propertyOf<Agent>('email_private'), title: columnNames.email_private },
  { name: propertyOf<Agent>('tax_nr'), title: columnNames.tax_nr },
  { name: propertyOf<Agent>('ust_nr'), title: columnNames.ust_nr },
  {
    name: propertyOf<Agent>('is_small_business'),
    title: columnNames.is_small_business,
    getCellValue: getTitelSmallBusiness,
  },
  { name: propertyOf<Agent>('bank_name'), title: columnNames.bank_name },
  { name: propertyOf<Agent>('iban'), title: columnNames.iban },
  { name: propertyOf<Agent>('bic'), title: columnNames.bic },
  { name: propertyOf<Agent>('jackplus_id'), title: columnNames.jackplus_id },
  { name: propertyOf<Agent>('myjack_login'), title: columnNames.myjack_login },
  { name: propertyOf<Agent>('status'), title: columnNames.status_title, getCellValue: getTitle },
  { name: propertyOf<Agent>('dismissal_reason'), title: columnNames.dismissal_reason },
  { name: propertyOf<Agent>('dismissed_at'), title: columnNames.dismissed_at },
  { name: propertyOf<Agent>('rank'), title: columnNames.rank_title, getCellValue: getTitle },
  {
    name: propertyOf<Agent>('branch_office'),
    title: columnNames.branch_office_title,
    getCellValue: getTitle,
  },
  { name: propertyOf<Agent>('agent_account_nr'), title: columnNames.agent_account_nr },
  { name: propertyOf<Agent>('business_registration_date'), title: columnNames.business_registration_date },
];

export default columns;
