function normalizeAllServices(services) {
  const normalizedServices = services.map((item) => ({
    id: item.id ? item.id : '',
    title: item.title ? item.title : '',
    default_price: item.default_price ? item.default_price : 0,
    type: item.type ? item.type : '',
    tax: item.tax.percent ? item.tax.percent : 0,
    fixed_start_month: item.fixed_start_month ? item.fixed_start_month : '',
    booking_account: item.booking_account.title ? item.booking_account.title : '',
  }));
  return normalizedServices;
}

export default normalizeAllServices;
