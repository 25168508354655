import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import ReceiptInput from '../../../../types/ReceiptInput.types';
import { MONTHS } from '../../../../services/config/CONST';
import getClosingDataPeriod from '../../../../Apollo/queries/getClosingDataPayrollPeriod';

function ReceiptEditMonth() {
  const { setFieldValue, handleBlur, values, errors, touched } = useFormikContext<ReceiptInput>();
  const { data } = useQuery(getClosingDataPeriod);
  const [dateOfLastPayloadArr, setDateOfLastPayloadArr] = useState('');

  useEffect(() => {
    setDateOfLastPayloadArr(data?.getClosingDataPayrollPeriod);
  }, [data]);

  const lastClosedPeriodMonth = new Date(dateOfLastPayloadArr).getUTCMonth();
  const filteredMonth = lastClosedPeriodMonth === 12 ? MONTHS : MONTHS.slice(lastClosedPeriodMonth, 12);

  return (
    <Autocomplete
      id="ReceiptEditAgent"
      fullWidth
      options={filteredMonth}
      getOptionLabel={(month) => `${month.title}`}
      onChange={(_, value) => {
        setFieldValue('month', value?.id || '');
      }}
      value={filteredMonth.find((item) => item.id === values.month) || null}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          name="month"
          label="Monat"
          fullWidth
          required
          error={Boolean(errors.month && touched.month) || false}
          helperText={errors.month && touched.month && errors.month}
        />
      )}
    />
  );
}

export default ReceiptEditMonth;
