import { Receipt } from 'global-intern-types';
import { MONTHS } from '../../../../services/config/CONST';
import { BookingAccount } from '../../../../types/BookingAccount.types';
import { Tax } from '../../../../types/Tax.types';
import { User } from '../../../../types/User.types';
import columnNames from '../../../AgentTable/i18n/columnNames';

function findTaxPrecentAndCode(row: Receipt, tax: Tax[]) {
  const findTaxObject = tax.find((taxItem: Tax) => row.tax_id === taxItem.id);
  const taxPercentAndCode = `${findTaxObject?.percent}% : ${findTaxObject?.code}`;
  return taxPercentAndCode;
}

export function columns(user: User[], bookingAccount: BookingAccount[], tax: Tax[]) {
  const columnsDef = [
    { name: 'id', title: 'ID' },
    {
      name: 'author_id',
      title: 'Author',
      getCellValue: (row: Receipt) => user.find((userItem) => userItem.id === row.author_id)?.username,
    },
    {
      name: 'agent_id',
      title: columnNames.agent_id,
      getCellValue: (row: Receipt) => row.agent.agent_id,
    },
    {
      name: 'surname',
      title: columnNames.surname,
      getCellValue: (row: Receipt) => row.agent?.surname || 'unbekannt',
    },
    {
      name: 'name',
      title: columnNames.name,
      getCellValue: (row: Receipt) => row.agent?.name || 'unbekannt',
    },
    {
      name: 'booking_account_id',
      title: columnNames.booking_account_id,
      getCellValue: (row: Receipt) =>
        bookingAccount.find((BookingAccountItem) => row.booking_account_id === BookingAccountItem.id)?.title,
    },
    {
      name: 'tax_id',
      title: columnNames.tax_id,

      getCellValue: (row: Receipt) => findTaxPrecentAndCode(row, tax),
    },
    {
      name: 'price',
      title: columnNames.price,
      getCellValue: (row: Receipt) => parseFloat(row.price.toString()).toFixed(2),
    },
    { name: 'description', title: columnNames.description },
    {
      name: 'exported_at',
      title: columnNames.exported_at,
      getCellValue: (row: Receipt) =>
        row.exported_at === null ? 'neu' : new Date(row.exported_at).toISOString().split('T')[0],
    },
    {
      name: 'month',
      title: 'Monat',
      getCellValue: (row: Receipt) => MONTHS.find((monthItem) => row.month === monthItem.id)?.title,
    },
    { name: 'year', title: 'Jahr' },
    {
      name: 'type',
      title: 'Art',
      getCellValue: (row: Receipt) => (row.type === 'IncomeReceipt' ? 'Eingehend' : 'Ausgehend'),
    },
  ];

  return columnsDef;
}

export const defaultFilterAndFormateValue = 'neu';
