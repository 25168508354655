// components
import AnalyticsIcon from '@mui/icons-material/Analytics';
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';
import SavingsIcon from '@mui/icons-material/Savings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import StoreIcon from '@mui/icons-material/Store';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SvgColor from '../../svg-color';
import { PagePathname } from '../../../pages';

// ----------------------------------------------------------------------

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'PRM',
    items: [
      { title: 'Dashboard', path: PagePathname.dashboard, icon: ICONS.dashboard },
      { title: 'Berater', path: PagePathname.alleBerater, icon: ICONS.user },

      {
        title: 'Berichte',
        path: '/berichte',
        icon: ICONS.analytics,
        children: [
          { title: 'Leistung', path: PagePathname.lesitungReport, icon: <AnalyticsIcon /> },
          { title: 'Karte', path: PagePathname.karte, icon: <MapIcon /> },
        ],
      },
      {
        title: 'Buchhaltung',
        path: '/buchhaltung',
        icon: <SavingsIcon />,
        children: [
          { title: 'Belege', path: PagePathname.alleBelege, icon: <ReceiptIcon /> },
          { title: 'Belege erstellen', path: PagePathname.belegeErstellen, icon: <AddToPhotosIcon /> },
          { title: 'Abschluss', path: PagePathname.abschluss, icon: <CheckCircleIcon /> },
          { title: 'Shopify Belege', path: PagePathname.shopifyBelege, icon: <StoreIcon /> },
        ],
      },
      {
        title: 'Einstellungen',
        path: '/einstellung',
        icon: <SettingsIcon />,
        children: [
          { title: 'Buchungskonten', path: PagePathname.buchungskonten },
          { title: 'Ränge', path: PagePathname.ränge },
          { title: 'Level', path: PagePathname.levels },
          { title: 'Benutzer', path: PagePathname.benutzer },
          { title: 'Marken', path: PagePathname.marken },
          { title: 'Leistung', path: PagePathname.leistungen },
          { title: 'Steuern', path: PagePathname.steuern },
          { title: 'Länder', path: PagePathname.länder },
          { title: 'Vertragsarten', path: PagePathname.vertragsarten },
          { title: 'Info', path: PagePathname.adminPanel },
        ],
      },
    ],
  },
];

export default navConfig;
