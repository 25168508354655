function commtChanges({ added, changed, rows, loaderFun, createTax, updateTax }) {
  let changedRows;
  if (added) {
    const startingAddedId = rows.length > 0 ? rows[rows.length - 1].id + 1 : 0;
    changedRows = [
      ...rows,
      ...added.map((row, index) => ({
        id: startingAddedId + index,
        ...row,
      })),
    ];
    const currentTax = {
      code: changedRows[rows.length].code,
      percent: parseInt(changedRows[rows.length].percent, 10),
    };
    if (changedRows) {
      createTax({ variables: { input: currentTax } }).then((result) => {
        if (result.data.createTax) loaderFun.loadTax();
      });
    }
  }

  if (changed) {
    const currentRows = [...rows];
    changedRows = rows.map((row) => (changed[row.id] ? { ...row, ...changed[row.id] } : row));
    let editRowID;
    let editRow = {};
    for (let i = 0; i < currentRows.length; i += 1) {
      if (currentRows[i] !== changedRows[i]) {
        editRowID = changedRows[i].id;
        editRow = {
          code: changedRows[i].code.toString(),
          percent: parseInt(changedRows[i].percent, 10),
        };
      }
    }

    if (editRow) {
      updateTax({
        variables: {
          variables: {
            code: editRow.code,
            percent: editRow.percent,
          },
          id: editRowID,
        },
      }).then((result) => {
        if (result.data.updateTax) loaderFun.loadTax();
      });
    }
  }
}

export default commtChanges;
