import React from 'react';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';

interface Props {
  onExecute: () => void;
}

function CommitButton({ onExecute }: Props) {
  return (
    <IconButton onClick={onExecute} title="Save changes">
      <SaveIcon />
    </IconButton>
  );
}

export default CommitButton;
