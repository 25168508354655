import React, { useEffect, useState } from 'react';

import { Button, Grid, TextField } from '@mui/material';
import useRequest from '../../../services/useRequest';
import startServiceManagement from '../../../services/requestToServerSchemas/mutationSchemas/startServiceManagement';

const serviceManagementValues = {
  serviceName: 'Wiki',
  status: true,
};

interface EnvJson {
  GIT_COMMIT: string;
  GIT_COMMIT_TIME: string;
  CI_PIPELINE_ID: string;
  CI_COMMIT_BRANCH: string;
}

export default function AdminPage() {
  const [appInformation, setAppInformation] = useState<EnvJson>({
    GIT_COMMIT: '',
    GIT_COMMIT_TIME: '',
    CI_PIPELINE_ID: '',
    CI_COMMIT_BRANCH: '',
  });
  const { response: startServiceResponse, request: requestServiceManagement, error } = useRequest();

  function getAppBuildInformation(): Promise<EnvJson> {
    return fetch('/static/env.json')
      .then((response) => response.json())
      .then((responseJson) => responseJson)
      .catch((e) => {
        console.error(e);
      });
  }

  useEffect(() => {
    getAppBuildInformation().then((response) => {
      setAppInformation(response);
    });
  }, []);

  const startServiceManagementRequest = () => {
    requestServiceManagement('mutation', startServiceManagement, serviceManagementValues);
  };
  return (
    <Grid xs={12} container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={5}>
      <Grid item>
        {appInformation.GIT_COMMIT && <div>GIT_COMMIT: {appInformation.GIT_COMMIT}</div>}
        {appInformation.GIT_COMMIT_TIME && <div>GIT_COMMIT_TIME: {appInformation.GIT_COMMIT_TIME}</div>}
        {appInformation.CI_PIPELINE_ID && <div>CI_PIPELINE_ID: {appInformation.CI_PIPELINE_ID}</div>}
        {appInformation.CI_COMMIT_BRANCH && <div>CI_COMMIT_BRANCH: {appInformation.CI_COMMIT_BRANCH}</div>}
        <div>REACT_APP_ENV: {process.env.REACT_APP_ENV}</div>
        <div>NODE_ENV: {process.env.NODE_ENV}</div>

        <Button onClick={startServiceManagementRequest} variant="contained">
          START SERVICE MANAGEMENT
        </Button>
      </Grid>
      <Grid item xs={12} container>
        <TextField
          variant="outlined"
          multiline
          rows={20}
          fullWidth
          inputProps={{ resize: 'both' }}
          value={error || JSON.stringify(startServiceResponse)}
        />
      </Grid>
    </Grid>
  );
}
