import { AgentInputType } from '../types/AgentInputType.types';
import Agentv2 from '../types/Agentv2.types';
import PRMBackendAgentInput from '../types/PRMBackendAgentInput.types';

function formatAgentinputForQuery(agentData: Agentv2 | AgentInputType): PRMBackendAgentInput {
  const agentInput: PRMBackendAgentInput = {
    branch_office_id: agentData.branch_office_id,
    rank_id: agentData.rank_id,
    country_id: agentData.country_id,
    status_id: agentData.status_id,
    name: agentData.name,
    surname: agentData.surname,
    birthday: new Date(agentData.birthday),
    is_male: agentData.is_male,
    city: agentData.city,
    address: agentData.address,
    zip: agentData.zip,
    email_private: agentData.email_private,
    is_small_business: agentData.is_small_business,
  };
  if (agentData.contract_type_id) agentInput.contract_type_id = agentData.contract_type_id;
  if (agentData.level_id) agentInput.level_id = agentData.level_id;
  if (agentData.photo) agentInput.photo = agentData.photo;
  if (agentData.city2) agentInput.city2 = agentData.city2;
  if (agentData.address2) agentInput.address2 = agentData.address2;
  if (agentData.zip2) agentInput.zip2 = agentData.zip2;
  if (agentData.phone) agentInput.phone = agentData.phone;
  if (agentData.mobile) agentInput.mobile = agentData.mobile;
  if (agentData.fax) agentInput.fax = agentData.fax;
  if (agentData.email_corp) agentInput.email_corp = agentData.email_corp;
  if (agentData.agent_id) agentInput.agent_id = agentData.agent_id;
  if (agentData.jackplus_id) agentInput.jackplus_id = agentData.jackplus_id;
  if (agentData.business_owner) agentInput.business_owner = agentData.business_owner;
  if (agentData.business_registration_date)
    agentInput.business_registration_date = new Date(agentData.business_registration_date);
  if (agentData.tax_nr) agentInput.tax_nr = agentData.tax_nr;
  if (agentData.ust_nr) agentInput.ust_nr = agentData.ust_nr;
  if (agentData.manager_id) agentInput.manager_id = agentData.manager_id;
  if (agentData.bank_name) agentInput.bank_name = agentData.bank_name;
  if (agentData.iban) agentInput.iban = agentData.iban;
  if (agentData.bic) agentInput.bic = agentData.bic;
  if (agentData.notes) agentInput.notes = agentData.notes;
  if (agentData.worker_passport_give_date)
    agentInput.worker_passport_give_date = new Date(agentData.worker_passport_give_date);
  if (agentData.country2_id) agentInput.country2_id = agentData.country2_id;
  if (agentData.dismissal_reason) agentInput.dismissal_reason = agentData.dismissal_reason;
  if (agentData.start_date) agentInput.start_date = new Date(agentData.start_date);
  if (agentData.agent_account_nr) agentInput.agent_account_nr = agentData.agent_account_nr;
  if (agentData.dismissed_at) agentInput.dismissed_at = new Date(agentData.dismissed_at);
  if (agentData.reverse_charge) agentInput.reverse_charge = agentData.reverse_charge;
  if (agentData.reverse_charge) agentInput.reverse_charge = agentData.reverse_charge;
  if (agentData.lat) agentInput.lat = agentData.lat;
  if (agentData.lon) agentInput.lon = agentData.lon;
  if (agentData.myjack_login) agentInput.myjack_login = agentData.myjack_login;

  return agentInput;
}

export default formatAgentinputForQuery;
