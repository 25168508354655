import React from 'react';

interface Props {
  value: boolean;
}
function BooleanFormatter({ value }: Props) {
  return <span>{value ? 'Ja' : 'Nein'}</span>;
}

export default BooleanFormatter;
