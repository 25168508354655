import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps, Typography } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );
  const logo = (
    <Box
      ref={ref}
      component="img"
      sx={{
        width: 120,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
      src="/static/img/logo.svg"
    />
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {process.env.REACT_APP_ENV === 'development' ? (
        <>
          <Typography variant="h6" sx={{ color: 'red', fontWeight: 'bold' }}>
            DEV
          </Typography>
          {logo}
        </>
      ) : (
        logo
      )}
    </Link>
  );
});

Logo.displayName = 'Logo';

export default Logo;
